import axios from '@/libs/axios';
import axiosPortal from '@/libs/axios-portal';
import Banners from '@/api/banners';
import Blogs from '@/api/blogs';
import CouponTransport from '@/api/couponTransport';
import CustomRedirect from '@/api/customRedirect';
import MetaTags from '@/api/metaTags';
import Products from '@/api/products';
// import ShopeeVoucher from '@/api/shopeeVoucher';
import Suppliers from '@/api/suppliers';
import osSuppliers from '@/api/osSuppliers';
import Vouchers from '@/api/vouchers';
import osVouchers from '@/api/osVouchers';
import VoucherCategory from '@/api/voucherCategories';
import Live from '@/api/live';
import PopupIcons from '@/api/popupIcons';
import systemSetting from '@/api/systemSetting';
import intentPopup from '@/api/intent-popup';
import hotDeal from '@/api/hotDeal';
import video from '@/api/video';
import loyalty from '@/api/loyalty';

export default (context, inject) => {
    // Initialize API factories
    const factories = {
        banners: Banners(axios),
        blogs: Blogs(axios),
        couponTransport: CouponTransport(axios),
        customRedirect: CustomRedirect(axios),
        metaTags: MetaTags(axios),
        products: Products(axios),
        // shopeeVoucher: ShopeeVoucher(axios),
        suppliers: Suppliers(axiosPortal),
        osSuppliers: osSuppliers(axios),
        osVouchers: osVouchers(axios),
        voucherCategory: VoucherCategory(axiosPortal),
        live: Live(axios),
        popupIcons: PopupIcons(axios),
        systemSetting: systemSetting(axios),
        intentPopup: intentPopup(axios),
        hotDeal: hotDeal(axios),
        video: video(axios),
        loyalty: loyalty(axiosPortal),
        vouchers: Vouchers(axiosPortal),
    };

    // Inject $api
    inject('api', factories);
};
