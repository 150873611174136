<template>
    <div class="cus-bg-banner relative pt-4 lg:pt-7">
        <div class="pl-2 md:pl-5 md:pr-5 xl:px-0 xl:pb-16 max-w-1200 m-auto">
            <Slider
                class="pb-3"
                :option="swiperOption"
                :loading="loadedBanner < bannerData.length"
                skeleton-class="w-[170px] h-[263px] sm:w-[186px] sm:h-[290px] md:w-[174px] md:h-[272px] lg:w-[238px] lg:h-[372px] xl:w-[292px] xl:h-[456px]"
            >
                <div v-for="(banner, index) in bannerData" :key="index" class="w-[170px] sm:w-[186px] md:w-[174px] lg:w-[238px] xl:w-[292px]">
                    <a :target="(banner.externalLink || `/`).includes('bloggiamgia.vn') ? '' : '_blank'" :href="banner.externalLink || '/'" :rel="(banner.externalLink || `/`).includes('bloggiamgia.vn') ? '' : 'nofollow'">
                        <img
                            class="rounded-lg"
                            :src="toImage(banner.image, 'full')"
                            alt="banner"
                            @load="onImgLoad"
                        >
                    </a>
                </div>
            </Slider>
        </div>
        <div class="p-2 md:p-5 lg:absolute w-full lg:-bottom-24 z-10">
            <div class="flex max-w-5xl mx-auto mb-2">
                <div class="pr-2 pl-5 py-2 shadow-lg input-search bg-white w-full rounded-lg lg:rounded-xl lg:px-2 lg:py-1 lg:pl-4 flex overflow-hidden">
                    <input
                        v-model="searchKey"
                        class="w-full text-sm lg:text-lg"
                        placeholder="Tìm kiếm"
                        style="outline: none;"
                        @keyup.enter="onSearch"
                    >
                    <div class="p-3 lg:m-0 flex flex-col justify-center border-0 rounded-xl overflow-hidden cursor-pointer" style="background-color: #008A07;" @click="onSearch">
                        <i class="el-icon-search text-xl lg:text-3xl text-white" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { image as toImage } from '~/utils/url';
    import Slider from '@/components/Slider.vue';

    export default {
        components: { Slider },
        props: {
            banners: {
                type: Array,
            },
        },
        data() {
            const bannerData = this.banners.length > 4 ? this.banners : [...this.banners, ...this.banners];
            return {
                bannerData,
                searchKey: '',
                suggestsearchKey: ['Mã Shopee 30K', 'Mã freeship Lazada', 'Huggies'],
                loadedBanner: 0,
                swiperOption: {
                    isLoop: true,
                    scroll: {
                        scrollFast: 1,
                        showBottomScroll: false,
                    },
                    autoPlay: {
                        isAuto: true,
                        timeStep: 4000,
                        slideStep: 1,
                        direction: 'ltr',
                    },
                    spacing: 12,
                },
            };
        },
        methods: {
            toImage,
            onImgLoad() {
                this.loadedBanner += 1;
            },
            onSearch() {
                if (this.searchKey.includes('shope') || this.searchKey.includes('shp.ee')) {
                    return this.$router.push(`/shopee?url=${encodeURI(this.searchKey)}`);
                }
                if (this.searchKey.includes('lazada')) {
                    this.$store.dispatch('queryLazada/setUrlHistoryVoucherLazada', encodeURI(this.searchKey));
                    return this.$router.push({ path: '/lazada' });
                }
                this.$router.push(`/ma-giam-gia?keyword=${encodeURI(this.searchKey)}`);
            },
            quickSearch(searchKey) {
                this.$router.push(`/ma-giam-gia?keyword=${encodeURI(searchKey)}`);
            },
        },
    };
</script>

<style lang="scss">
.cus-bg-banner {
        .search {
            .el-input__inner {
                border: none !important;
            }
        }
    }
        .cus-bg-banner {
            background-color: #FEF8F2;
            margin-top:55px;
        }
    @media (min-width: 768px) {
        .cus-bg-banner {
            margin-top:70px;
        }
        .swiper-slide {
            width: 25%;
        }
    }
</style>
