import axios from 'axios';
import { getCookie } from './getCookies';

const instance = axios.create({
    baseURL: process.env.API_HOST,
    headers: {
        'content-type': 'application/json',
    },
});

if (process.browser === true) {
    instance.defaults.baseURL = process.env.API_PATH;
    instance.interceptors.request.use((config) => {
        const token = getCookie('auth._token.local');
        config.headers.Authorization = decodeURI(token);
        return config;
    });
}

export default instance;
