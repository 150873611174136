<template>
    <div class="h-full min-h-[140px] relative ticket-wrap">
        <div
            :id="voucher.id"
            v-click-outside="hideDetail"
            class="rounded-lg w-full"
            :class="`${!showDetail ? 'ticket' : ''}`"
        >
            <div
                v-if="!showDetail"
                class="flex flex-col justify-start cursor-pointer pt-3 pb-2 mx-auto relative h-full"
                @click="showDetail=true"
                @click.stop="1"
            >
                <div class="flex">
                    <div class="logo-supplier">
                        <div class="flex flex-col justify-center border-r-2 border-dashed pr-2 ml-6 items-center" style="min-width: 95px">
                            <nuxt-img class="rounded-[5px] w-[100px]" :src="toImage(voucher.avatar || '')" />
                        </div>
                    </div>
                    <div class="pl-3 pr-5 w-full min-h-[92px]">
                        <div>
                            <div class="mini-title-supplier font-semibold text-left relative">
                                <nuxt-img class="rounded-[5px] max-[280px]:w-[75px] w-[95px] right-0 top-0 absolute" :src="toImage(voucher.avatar || '')" />
                            </div>
                            <div class="max-[280px]:text-[11px] md:text-xs mb-1 md:mb-0 flex items-center">
                                <div v-if="voucher.couponType === 'coin'" class="text-sm font-semibold">
                                    Hoàn
                                </div>
                                <div v-else class="text-sm font-semibold">
                                    Giảm
                                </div>
                                <div class="font-bold text-lg md:text-2xl ml-1" style="color: #008A07">
                                    <span v-if="voucher.couponType === 'value'">
                                        {{ voucher.discountAmount / 1000 | formatNumber }}K
                                    </span>
                                    <span v-if="voucher.couponType === 'coin' && voucher.discountAmount > 1000">
                                        {{ voucher.discountAmount / 1000 | formatNumber }}K Xu
                                    </span>
                                    <span v-if="voucher.couponType === 'coin' && voucher.discountAmount < 1000">
                                        {{ voucher.discountAmount }}%
                                    </span>
                                    <span v-if="voucher.couponType === 'percent'">
                                        {{ voucher.discountAmount }}%
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="max-[280px]:text-[11px] md:text-xs mb-1">
                            <span class="font-normal">ĐH tối thiểu: </span>
                            <span class="font-semibold">{{ (voucher.minSpend || 0) | formatNumber }}đ</span>
                        </div>
                        <div v-if="voucher.targetUser == 'new'" class="max-[280px]:text-[11px] md:text-xs mb-1">
                            <span class="font-normal">Đối tượng: </span>
                            <span class="font-semibold">
                                {{ getTargetLabel }}
                            </span>
                        </div>
                        <div class="flex items-center mb-1">
                            <span class="italic text-left text-[#008a08] text-[12px] md:text-xs w-[70%] font-semibold">
                                Lưu ý: {{ voucher.note?.slice(0, 35) }}... <span class="text-black font-normal text-[11px]">Xem chi tiết</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="flex mini-action justify-between pr-2">
                    <div class="flex expried-date justify-center min-w-[106px] pl-2 mr-2">
                        <div v-if="(voucher.startAt) < dayjs(new Date()).unix()*1000" class="flex flex-wrap justify-center items-center" :class="!voucher.expiredAt && 'flex-col'">
                            <span class="text-right text-[12px] mr-1">
                                <i class="el-icon-time" /> HSD:
                            </span>
                            <span class=" text-left italic text-[12px]">
                                {{ voucher.expiredAt ? dayjs(voucher.expiredAt).format('DD/MM') : 'Đang cập nhật' }}
                            </span>
                        </div>
                        <div v-if="(voucher.startAt) > dayjs(new Date()).unix()*1000" class="flex flex-wrap md:justify-center items-center text_custom md:max-w-[95px] w-full">
                            <span class="text-center text-[#017407] mr-1">
                                <i class="el-icon-time" /> Hiệu lực:
                            </span>
                            <span class="italic text-xs text-center md:mx-auto" style="font-size:12px">
                                {{ (voucher.startAt) ? dayjs((voucher.startAt)).format('HH:mm DD/MM') : 'Đang cập nhật' }}
                            </span>
                        </div>
                    </div>
                    <div class="flex w-full justify-between items-center text-[#008A07] gap-2">
                        <div class="hashtag flex ml-1 border border-solid bg-[#FFF5E6] border-[#FE9800] text-[#292929] px-2 rounded items-center gap-1">
                            <span class="font-mono italic">
                                #
                            </span>
                            <p class="font-sans italic text-xs">
                                Độc quyền
                            </p>
                        </div>
                        <div
                            class="flex cursor-pointer"
                            @click="copyCouponCode(voucher.voucherCode, voucher.affLink)"
                            @click.stop="1"
                        >
                            <button
                                class="font-semibold rounded-lg px-1 py-1 text-[#017407] transition-all duration-300 text-center text-[12px] w-[105px] h-[28px] bg-[#EDFFEE] overflow-hidden overflow-ellipsis"
                            >
                                <i v-if="statusCopy" class="el-icon-check" /> {{ statusCopy ? 'Đã Copy': 'Copy mã' }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="showDetail"
                :id="voucher.id"
                class="bg-white relative mx-auto pt-3 pb-2 px-6 mb-2 sm:absolute z-40 overflow-hidden rounded-lg shadow-lg border w-full"
                @click="hideDetail"
                @click.stop="1"
            >
                <div class="font-semibold text-3xl mt-1 mb-3" style="color: #008A07">
                    <span v-if="voucher.couponType === 'value'">
                        Giảm {{ voucher.discountAmount / 1000 | formatNumber }}K
                    </span>
                    <span v-if="voucher.couponType === 'coin' && voucher.discountAmount > 1000">
                        Hoàn {{ voucher.discountAmount / 1000 | formatNumber }}K
                    </span>
                    <span v-if="voucher.couponType === 'coin' && voucher.discountAmount < 1000">
                        Hoàn {{ voucher.discountAmount }}%
                    </span>
                    <span v-if="voucher.couponType === 'percent'">
                        Giảm {{ voucher.discountAmount }}%
                    </span>
                </div>
                <div>
                    <div v-if="voucher.discountAmount" class="text-sm mb-2">
                        <span class="font-normal">Tối đa: </span>
                        <span v-if="voucher.couponType === 'value'" class="font-semibold">
                            {{ voucher.discountAmount / 1000 | formatNumber }}K
                        </span>
                        <span v-if="voucher.couponType === 'coin' && voucher.discountAmount > 1000" class="font-semibold">
                            Hoàn {{ voucher.discountAmount / 1000 | formatNumber }}K
                        </span>
                        <span v-if="voucher.couponType === 'coin' && voucher.discountAmount < 1000" class="font-semibold">
                            Hoàn {{ voucher.discountReward | formatNumber }} Xu
                        </span>
                        <span v-if="voucher.couponType === 'percent'" class="font-semibold">
                            {{ voucher.discountReward | formatNumber }}đ
                        </span>
                    </div>
                    <div class="text-sm mb-2">
                        <span class="font-normal">ĐH tối thiểu: </span>
                        <span class="font-semibold">{{ (voucher.minSpend || 0) | formatNumber }}đ</span>
                    </div>
                    <div v-if="voucher.targetUser == 'new'" class="text-sm mb-2">
                        <span class="font-normal">Đối tượng: </span>
                        <span class="font-semibold">
                            {{ getTargetLabel }}
                        </span>
                    </div>
                    <div v-if="voucher.usageTerms" class="text-sm mb-2">
                        <span class="font-normal">Thể lệ: </span>
                        <span class="font-semibold capitalize whitespace-pre-line">
                            {{ voucher.usageTerms }}
                        </span>
                    </div>
                    <div v-if="voucher.note" class="text-sm mb-2">
                        <span class="font-bold" style="color:#ff5722">Lưu ý: </span>
                        <span class="font-semibold capitalize whitespace-pre-line">
                            {{ voucher.note }}
                        </span>
                    </div>
                </div>
                <div class="flex justify-center pt-3">
                    <div class="flex justify-center  w-full items-center text-[#008A07] gap-2">
                        <div class="hashtag flex ml-1 border border-solid bg-[#FFF5E6] border-[#FE9800] text-[#292929] px-2 rounded items-center gap-1">
                            <span class="font-mono italic">
                                #
                            </span>
                            <p class="font-sans italic text-xs">
                                Độc quyền
                            </p>
                        </div>
                        <div
                            class="flex ml-3 cursor-pointer"
                            @click="copyCouponCode(voucher.voucherCode, voucher.affLink)"
                            @click.stop="1"
                        >
                            <button
                                class="font-semibold rounded-lg px-1 py-1 hover:bg-orange text-[#017407] transition-all duration-300 text-center text-[12px] w-[105px] h-[28px] bg-[#EDFFEE] overflow-hidden overflow-ellipsis"
                            >
                                <i v-if="statusCopy" class="el-icon-check" /> {{ statusCopy ? 'Đã Copy': 'Copy mã' }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ClickOutside from 'vue-click-outside';
    import dayjs from 'dayjs';
    import cloneDeep from 'lodash/cloneDeep';
    import {
        NORMAL as TARGET_NORMAL,
        OPTIONS as TAGRET_OPTIONS,
    } from '~/constants/voucher/target';
    import { image as toImage } from '~/utils/url';
    import _find from 'lodash/find';

    export default {
        directives: {
            ClickOutside,
        },
        props: {
            voucher: {
                type: Object,
                required: true,
            },
            suppliers: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                cloneData: cloneDeep(this.voucher),
                TAGRET_OPTIONS,
                showDetail: false,
                statusCopy: false,
            };
        },
        computed: {
            supplier() {
                return _find(this.suppliers, (supplier) => supplier.id === this.voucher.supplierId) || {};
            },
            getTargetLabel() {
                const _value = this.voucher.targetUser || TARGET_NORMAL;
                const target = _find(TAGRET_OPTIONS, (option) => option.value === _value);
                return target.label;
            },
        },
        methods: {
            dayjs,
            toImage,
            hideDetail() {
                if (this.showDetail) {
                    this.showDetail = false;
                }
            },
            async copyCouponCode(couponCode, link) {
                try {
                    this.$message({
                        message: 'Copy voucher thành công ',
                        type: 'success',
                    });
                    const input = document.createElement('input');
                    input.setAttribute('value', couponCode);
                    document.body.appendChild(input);
                    input.select();
                    document.execCommand('copy');
                    document.body.removeChild(input);
                    this.statusCopy = true;
                    setTimeout(() => {
                        window.open(link, '_blank', 'noreferrer,noopener');
                    }, 700);
                } catch (error) {
                    this.$message.error('Có lỗi xảy ra vui lòng thử lại sau');
                }
            },
        },
    };
</script>

<style lang="scss">
</style>
