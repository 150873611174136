const state = () => ({
    statusFormLogin: false,
    statusFormRegister: false,
});

const actions = {
    async setStatusFormLogin({ commit }, status) {
        commit('setStatusFormLogin', status);
    },
    async setStatusFormRegister({ commit }, status) {
        commit('setStatusFormRegister', status);
    },
};

const mutations = {
    setStatusFormLogin(state, status) {
        state.statusFormLogin = status;
    },
    setStatusFormRegister(state, status) {
        state.statusFormRegister = status;
    },
};

export {
    state,
    actions,
    mutations,
};
