<template>
    <div
        v-if="voucher.type === 'wallet'"
        class="flex ml-3 cursor-pointer"
        @click="openLinkVoucher()"
        @click.stop="1"
    >
        <button
            class="font-semibold rounded-lg px-1 py-1 text-[#017407] transition-all duration-300 text-center text-[12px] w-[105px] h-[28px] bg-[#EDFFEE] overflow-hidden overflow-ellipsis"
        >
            Mua Ngay
        </button>
    </div>
    <div
        v-else-if="voucher.type === 'manual_entry'"
        class="flex ml-3 cursor-pointer"
        @click="copyCouponCode(voucher.voucherCode)"
        @click.stop="1"
    >
        <button
            class="font-semibold rounded-lg px-1 py-1 text-[#017407] transition-all duration-300 text-center text-[12px] w-[105px] h-[28px] bg-[#EDFFEE] overflow-hidden overflow-ellipsis"
        >
            <i v-if="statusCopy" class="el-icon-check" /> {{ statusCopy ? 'Đã Copy': 'Copy Mã' }}
        </button>
    </div>
    <div v-else class="flex ml-3 cursor-pointer">
        <a
            target="_blank"
            class="font-semibold rounded-lg px-1 py-1 text-white transition-all duration-300 text-center text-[12px] w-[105px] h-[28px] bg-[#FF9900]"
            @click="goBanner"
            @click.stop="1"
        >
            Đến Banner
        </a>
    </div>
</template>
<script>
    import _sample from 'lodash/_arraySample';
    import { FLASH_SALE_SHOPEE_LINK, walletShopee, sendoLinkRedirect } from '@/constants/common';

    export default {
        props: {
            voucher: {
                type: Object,
                required: true,
            },
            productLink: {
                type: String,
            },
        },
        data() {
            return {
                statusCopy: false,
            };
        },
        methods: {
            async copyCouponCode(voucherCode) {
                try {
                    this.$message({
                        message: 'Dán voucher vừa copy vào ô Nhập voucher',
                        type: 'success',
                    });
                    let cleanLink = this.productLink || (this.voucher?.supplier?.slug === 'shopee' ? _sample(FLASH_SALE_SHOPEE_LINK) : this.voucher?.affLink);

                    if (this.voucher?.supplier?.slug === 'sendo') {
                        cleanLink = sendoLinkRedirect;
                    }
                    if (this.voucher.isInWallet) {
                        window.open(cleanLink, '_blank', 'noreferrer,noopener');
                    } else {
                        const input = document.createElement('input');
                        input.setAttribute('value', voucherCode);
                        document.body.appendChild(input);
                        input.select();
                        document.execCommand('copy');
                        document.body.removeChild(input);
                        this.statusCopy = true;
                        setTimeout(() => {
                            window.open(cleanLink, '_blank', 'noreferrer,noopener');
                        }, 700);
                    }
                } catch (error) {
                    this.$message.error('Có lỗi xảy ra vui lòng thử lại sau');
                }
            },
            openLinkVoucher() {
                let cleanLink = this.productLink || (this.voucher?.supplier?.slug === 'shopee' ? walletShopee : this.voucher?.affLink);
                if (this.voucher?.supplier?.slug === 'sendo') {
                    cleanLink = sendoLinkRedirect;
                }
                window.open(cleanLink, '_blank', 'noreferrer,noopener');
            },
            goBanner() {
                const redirectLink = this.voucher?.supplier?.slug === 'sendo' ? sendoLinkRedirect : this.voucher.affLink;
                window.open(redirectLink, '_blank', 'noreferrer,noopener');
            },
        },
    };
</script>
<style lang="scss">

</style>
