<template>
    <div
        v-if="dialogVisible"
        ref="container"
        class="download-app-banner fixed top-0 left-0 z-[1000] container-img
     bg-[#035007] h-[100dvh] w-screen flex flex-col justify-between gap-4 overflow-auto"
    >
        <img src="/images/bg-popup-download.webp" class="w-screen h-auto absolute top-0 -z-[1]">
        <div class="flex justify-end p-4">
            <div @click="close">
                <i
                    class="el-icon-error text-2xl text-white"
                />
            </div>
        </div>
        <div class="px-4">
            <div class="relative">
                <img src="/images/logo-noti.webp" class="w-[66px] h-[66px] bottom-0 left-4">
            </div>
            <h4 class="mt-2 text-white text-[30px] leading-[40px] font-semibold text-left">
                Nô Tì - Trợ lý săn sale
            </h4>
        </div>
        <div class="text-white px-4">
            <div class="flex flex-col gap-8">
                <div v-for="(item, index) in contents" :key="index" class="flex gap-2">
                    <img :src="item.icon" class="w-6 h-6">
                    <span class="text-sm font-medium">{{ item.text }}</span>
                </div>
            </div>
        </div>
        <div class="text-white px-4">
            <p class="text-base font-medium text-center">
                Tải App để dùng được nhiều tính năng hơn, nhiều ưu đãi hơn
            </p>
            <div class="flex mt-5">
                <el-button
                    plain
                    class="flex-1 bg-[#035007] !text-white border-white py-[14px]"
                    @click="close"
                    @click.stop="1"
                >
                    <span>Tiếp tục với Web</span>
                </el-button>
                <el-button
                    class="flex-1 text-[#008A07] bg-[#FFFFFF] py-[14px]"
                    @click="downloadApp"
                    @click.stop="1"
                >
                    <span>Tải App</span>
                </el-button>
            </div>
        </div>
        <div />
    </div>
</template>
<script>
    import dayjs from 'dayjs';
    import { image as toImage } from '~/utils/url';
    import { mapActions } from 'vuex';

    const contents = [
        {
            icon: '/images/icons/receive-gift.webp',
            text: 'Tặng 500 xu cho người mới',
        },
        {
            icon: '/images/icons/tool.webp',
            text: 'Công cụ Shopee live, Shopee video',
        },
        {
            icon: '/images/icons/coin.webp',
            text: 'Tích xu đổi quà lên đến 200K',
        },
        {
            icon: '/images/icons/voucher.webp',
            text: 'Ưu đãi độc quyền từ đối tác',
        },
        {
            icon: '/images/icons/gift.webp',
            text: 'Các quà tặng từ thương hiệu',
        },
    ];

    export default {
        props: {
            linkImg: String,
        },
        data() {
            return {
                dialogVisible: false,
                banner: '',
                closedBanner: {},
                checked: false,
                isPreview: false,
                isMobile: false,
                contents,
            };
        },
        mounted() {
            this.isMobile = window.screen.width < 768;
            const banner = localStorage.getItem('banner-download-app');
            if (!this.isMobile || banner === 'false' || (banner && Number(banner) > dayjs().unix())) {
                return;
            }
            this.setClosePopupSearch(true);
            this.dialogVisible = true;
        },
        methods: {
            toImage,
            ...mapActions('popup', ['setClosePopupSearch']),
            downloadApp() {
                localStorage.setItem('banner-download-app', false);
                this.dialogVisible = false;
                window.open('https://bgg.onelink.me/Rgy4/bggapp', '_blank');
            },
            close() {
                this.setClosePopupSearch(false);
                const exp = dayjs().add(24, 'hour').unix();
                localStorage.setItem('banner-download-app', JSON.stringify(exp));
                this.dialogVisible = false;
            },

        },
    };
</script>
<style lang="scss">
    body:has(.download-app-banner) {
        overflow: hidden !important;
    }
</style>
