<template>
    <div class="overflow-x-hidden">
        <Banner :banners="banners" />
        <ListOffer class="lg:pt-24" :suppliers="suppliers" />
        <div class="max-w-1200 mx-auto mt-6 md:mt-12">
            <ListVoucherHot
                class="max-w-1200 mx-auto"
                :suppliers="suppliers"
                :title="'Mã giảm giá HOT nhất'"
                :vouchers="hotVouchers.slice(0, 20)"
                :categories="categories"
            />
            <div class="flex justify-between">
                <nuxt-link :to="'/ma-giam-gia'" class="text-base m-auto font-semibold mb-2 mt-4 lg:mt-0 lg:text-xl">
                    Xem tất cả...
                </nuxt-link>
            </div>
        </div>
        <ListFAQ :faqs="faqs" />
        <ListNews :blogs="blogs" />
    </div>
</template>
<script>
    import Banner from '~/components/home/Banner.vue';
    import ListOffer from '~/components/home/ListOffer.vue';
    import ListVoucherHot from '~/components/home/ListVoucherHot.vue';
    import ListFAQ from '~/components/home/ListFAQ.vue';
    import ListNews from '~/components/home/ListNews.vue';

    const faqCategoryId = 1;

    export default {
        components: {
            Banner,
            ListOffer,
            ListVoucherHot,
            ListFAQ,
            ListNews,
        },
        async asyncData({ $api, store }) {
            const { data: banners } = await $api.banners.getBanners();
            const { data: suppliers } = await $api.suppliers.getSuppliers();
            const { data: hotVouchers } = await $api.vouchers.getVouchers({ sort: 'totalClick,DESC' });
            const { data: categories } = await $api.voucherCategory.getVoucherCategory({ isNotChildren: false });
            const { data: faqs } = await $api.blogs.getBlogByCategory(faqCategoryId);
            const { data: blogs } = await $api.blogs.getBlogs();
            await store.dispatch('hotDeal/getHotDealVouchers');
            await store.dispatch('hotDeal/getHotDealProducts');

            return {
                banners,
                hotVouchers: hotVouchers?.data,
                categories: categories.data,
                suppliers: suppliers.data,
                faqs,
                blogs,
            };
        },
        data() {
            return {
                searchKey: '',
                dialogVisible: false,
            };
        },
        methods: {
            handleClose(done) {
                this.$confirm('Are you sure to close this dialog?')
                    .then(() => {
                        done();
                    })
                    .catch(() => {});
            },
        },
        head() {
            let headLink = {};
            if (this.$route.path === '/') {
                headLink = {
                    link: [
                        {
                            rel: 'canonical',
                            href: 'https://bloggiamgia.vn/shopee',
                        },
                    ],
                };
            }
            return {
                headLink,
                link: [
                    {
                        rel: 'canonical',
                        href: 'https://bloggiamgia.vn',
                    },
                ],
                script: [{
                    type: 'application/ld+json',
                    innerHTML: JSON.stringify({
                        '@context': 'https://schema.org',
                        '@type': 'Organization',
                        name: 'Blog Giảm Giá',
                        url: 'https://bloggiamgia.vn/',
                        logo: 'https://bloggiamgia.vn/images/logo.svg',
                        alternateName: 'BGG',
                        sameAs: [
                            'https://www.facebook.com/bloggiamgia.vn',
                            'https://www.youtube.com/@BlogGiamGia',
                            'https://twitter.com/bloggiamgiavn',
                            'https://www.tumblr.com/bloggiamgiavn',
                            'https://www.pinterest.com/bloggiamgiavnn/',
                            'https://t.me/nghiensandeal',
                            'https://linkhay.com/blog/536009/blog-giam-gia',
                            'https://www.tiktok.com/@hoisandeal',
                            'https://baotuyenquang.com.vn//xa-hoi/cuoc-song/vao-ngay-bloggiamgiavn-de-lay-ngay-hang-ngan-voucher-shopee-day-gia-tri-174383.html',
                            'https://baotayninh.vn/me-bim-cham-vao-bloggiamgia-vn-san-ma-giam-gia-de-tiet-kiem-ca-trieu-dong-moi-thang-a159077.html',
                            'https://zingnews.vn/san-voucher-mua-sam-thuan-tien-hon-nho-bloggiamgiavn-post1420756.html',
                            'https://yeah1.com/doi-song/canh-bao-dung-chot-don-shopee-neu-ban-chua-vao-bloggiamgiavn-a59-d97039.html',
                            'https://vietbao.vn/biet-truoc-ma-giam-gia-shopee-nho-vao-bloggiamgia-vn-moi-ngay-417681.html',
                        ],
                        contactPoint: [
                            {
                                '@type': 'ContactPoint',
                                telephone: '+84889966741',
                                contactType: 'customer service',
                                email: 'contact@bloggiamgia.vn',
                            },
                        ],
                    }),
                }],
            };
        },
    };
</script>
