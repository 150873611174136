export default (axios) => ({
    getBlogs: (params) => axios.get('/b/Blog', { params }).then((_) => _.data),
    getBlogByCategory: (categoryId, params) => axios.get(`/b/Blog/category/${categoryId}`, { params }).then((_) => _.data),
    getBlog: (blogId) => axios.get(`/b/Blog/${blogId}`).then((_) => _.data),
    getBlogArchive: (blogId) => axios.get(`/b/Blog/archive/${blogId}`).then((_) => _.data),
    getBlogBySlug: (blogSlug) => axios.get(`/b/Blog/slug/${blogSlug}`).then((_) => _.data),

    getBlogCategories: (params) => axios.get('/b/BlogCategory', { params }),
    getBlogCategory: (id) => axios.get(`/b/BlogCategory/${id}`),
    getBlogCategoryBySlug: (slug) => axios.get(`/b/BlogCategory/bySlug/${slug}`),

    getBlogsHighLight: (params) => axios.get('/b/Blog/highlight', { params }),
    getBlogsNormal: (params) => axios.get('/b/Blog/normal', { params }),
});
