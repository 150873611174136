<template>
    <div class="text-white" style="background: #BA5B03;">
        <div class="block lg:hidden">
            <div class="mx-auto">
                <div class="text-lg flex flex-row justify-center font-semibold pt-8 pb-9">
                    <nuxt-link to="/" class="w-60 mt-7 text-center">
                        <nuxt-img
                            src="/images/logo-dark.svg"
                            alt="logo"
                            style="width:320px; height:92px"
                        />
                    </nuxt-link>
                </div>
                <div class="text-center mt-4">
                    <div class="text-base font-semibold">
                        Facebook
                    </div>
                    <a href="https://m.me/bloggiamgia.vn" class="text-sm">
                        m.me/bloggiamgia.vn
                    </a>
                </div>
                <div class="text-center mt-4">
                    <div class="text-base font-semibold">
                        Email
                    </div>
                    <a :href="`mailto:${email}`" class="text-sm">
                        {{ email }}
                    </a>
                </div>
                <div class="border-t border-white-100 mx-5 mt-8">
                    <div class="text-center mt-6">
                        Designed and developed by Bloggiamgia
                    </div>
                    <div>
                        <p class="text-xs mt-3 text-center">
                            CÔNG TY TNHH PIGGI
                            Giấy CNĐKDN: 3603827194 - Ngày cấp: 15/10/2021
                            Địa chỉ ĐKDN:  481 đường 29 tháng 3, Hoà Xuân, Cẩm Lệ, Đà Nẵng
                        </p>
                    </div>
                    <div class="flex justify-center pt-5 pb-6">
                        <a href="#">
                            <nuxt-img
                                class="w-6 mx-3"
                                src="/images/icons/facebook-circle.svg"
                                alt="Facebook"
                                style="width:24px; height:24px"
                            />
                        </a>
                        <a href="#">
                            <nuxt-img
                                class="w-6 mx-3"
                                src="/images/icons/instagram-fill.svg"
                                alt="Instagram"
                                style="width:24px; height:24px"
                            />
                        </a>
                        <a href="#">
                            <nuxt-img
                                class="w-6 mx-3"
                                src="/images/icons/youtube.svg"
                                alt="Youtube"
                                style="width:24px; height:24px"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="hidden lg:block max-w-1200 mx-auto lg:px-5">
            <div class="flex justify-between items-end" style="gap:20px">
                <div class="w-1/2">
                    <div class="uppercase text-lg font-semibold text-center">
                        <div class="flex flex-row justify-center mb-6">
                            <nuxt-link to="/" class="w-80 mt-7 text-center">
                                <nuxt-img
                                    src="/images/logo-dark.svg"
                                    alt="logo-dark"
                                    style="width:320px; height:92px"
                                    loading="lazy"
                                />
                            </nuxt-link>
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-4">
                        <div v-for="(contact, index) in contacts" :key="index" class="leading-6">
                            <div class="font-semibold">
                                {{ contact.label }}
                            </div>
                            <a :href="`${contact.prefix}${contact.value}`">
                                {{ contact.value }}
                            </a>
                        </div>
                        <div class="leading-6">
                            <div class="font-semibold">
                                Facebook
                            </div>
                            <a href="https://m.me/bloggiamgia.vn" class="text-sm">
                                m.me/bloggiamgia.vn
                            </a>
                        </div>
                    </div>
                </div>
                <div class="w-1/2">
                    <div class="uppercase text-lg font-semibold pb-2 border-b mt-12">
                        Danh mục
                    </div>
                    <div class="grid grid-cols-2 gap-4 mt-6">
                        <div v-for="(category, index) in categories" :key="index">
                            <a :href="`/ma-giam-gia/${category.slug}`" class="uppercase hover:underline">
                                {{ category.name }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full mx-auto border-t py-4 mt-6">
                <div class="text-center">
                    Designed and developed by Bloggiamgia
                </div>
            </div>
            <div class="w-full mx-auto items-center my-3">
                <p class="text-center text-xs">
                    CÔNG TY TNHH PIGGI
                    Giấy CNĐKDN: 3603827194 - Ngày cấp: 15/10/2021
                    Địa chỉ ĐKDN:  481 đường 29 tháng 3, Hoà Xuân, Cẩm Lệ, Đà Nẵng
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    const email = 'contact@bloggiamgia.vn';
    const emailSupport = 'contact@bloggiamgia.vn';
    const phone = '0123456789';
    const callCenter = '0123456789';
    const facebook = 'm.me/bloggiamgia.vn';
    const insta = 'm.me/bloggiamgia.vn';
    const youtube = 'm.me/bloggiamgia.vn';

    export default {
        data: () => ({
            email,
            emailSupport,
            phone,
            callCenter,
            socicals: [{
                name: 'Facebook',
                icon: 'fab fa-facebook',
                link: facebook,
            }, {
                name: 'Instagram',
                icon: 'fab fa-instagram',
                link: insta,
            }, {
                name: 'Youtube',
                icon: 'fab fa-youtube',
                link: youtube,
            }],
            contacts: [
                {
                    label: 'Email',
                    value: email,
                    prefix: 'mailto:',
                },
            ],
            categories: [{
                name: 'shopee',
                slug: 'shopee',
            }, {
                name: 'grab',
                slug: 'grab',
            }, {
                name: 'lazada',
                slug: 'lazada',
            }, {
                name: 'traveloka',
                slug: 'traveloka',
            }, {
                name: 'tiki',
                slug: 'tiki',
            }, {
                name: 'vntrip',
                slug: 'vntrip',
            }, {
                name: 'yes24',
                slug: 'yes24',
            }, {
                name: 'nguyen kim',
                slug: 'nguyen-kim',
            }, {
                name: 'now',
                slug: 'now',
            }, {
                name: 'klook',
                slug: 'klook',
            }],
        }),
    };
</script>
