import { render, staticRenderFns } from "./landingPage.vue?vue&type=template&id=66eaa9a6"
import script from "./landingPage.vue?vue&type=script&lang=js"
export * from "./landingPage.vue?vue&type=script&lang=js"
import style0 from "@/assets/scss/index.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports