<template>
    <div>
        <el-dialog
            :visible.sync="dialogVisible"
            :before-close="close"
            :close-on-click-modal="false"
            custom-class="popup-otp"
            top="20vh"
            append-to-body
            destroy-on-close
        >
            <LoadingForm :loading="loading" />
            <div class="bg-[#FFFFFF] rounded-lg">
                <div class="flex justify-center font-medium text-xl text-[#292929]">
                    Xác thực mã OTP
                </div>
                <el-form
                    ref="form"
                    class="p-3"
                >
                    <div class="flex justify-center font-semibold text-base text-[#003A03]">
                        Vui Lòng nhập mã OTP tại đây
                    </div>
                    <div class="flex justify-center gap-3 p-2">
                        <el-form-item
                            v-for="( item, index) in length"
                            :key="index"
                            class="mb-0"
                            prop="otp"
                        >
                            <el-input
                                :ref="'inputRefs' + index"
                                v-model="form[item - 1]"
                                type="number"
                                class="w-10 h-12 text-center text-2xl
                                font-extrabold border border-transparent  focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
                                @keyup.native="(event)=> handleKeyPress(event,form[index], index)"
                            />
                        </el-form-item>
                    </div>
                    <div>
                        <div v-if="countdown === 0" class="flex justify-center">
                            Bạn chưa nhận được OTP?<span class="text-[#008A07] text-sm font-medium" @click="getOtpCode">Gửi lại mã</span>
                        </div>
                        <div v-else class="flex justify-center text-[#292929] font-medium text-sm">
                            Thời gian còn lại: <span class="text-[#FE5722] ml-1">{{ formatTime(countdown) }}</span>
                        </div>
                        <el-button ref="submit" class="w-full bg-[#008A07] text-[#FFFFFF] text-sm rounded-full mt-3" @click="onSubmit">
                            Tiếp tục
                        </el-button>
                    </div>
                </el-form>
                <div class="p-3 text-[#AE4B4B] text-center">
                    Mã <span class="font-semibold">OTP</span> đã gửi cho bạn qua zalo <span class="font-semibold"> {{ data?.phoneNumber }}</span> từ <span class="font-semibold"> Zalo công ty Piggi</span> rồi nhé!
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import LoadingForm from './LoadingForm.vue';

    export default {
        components: {
            LoadingForm,
        },
        data() {
            return {
                dialogVisible: true,
                loading: false,
                form: [],
                length: 6,
                countdown: (4 * 60) + 59,

            };
        },
        computed: {
            ...mapState('auth', ['data']),
            ...mapState('auth', ['isRedirect']),
        },
        mounted() {
            this.startCountdown();
        },
        methods: {
            close() {
                this.dialogVisible = false;
                this.$emit('OtpPopup', false);
            },
            async getOtpCode() {
                await this.$apiPortal.auth.otpNewPass({ phoneNumber: this.data.phoneNumber });
                this.countdown = (4 * 60) + 59;
                this.startCountdown();
            },
            onSubmit() {
                const valueOtp = this.form.join('');
                const dataLogin = { ...this.data, otp: valueOtp };
                if (this.form.length === 0 || this.form.otp === '') {
                    this.$message({
                        message: 'Bạn cần xác nhận OTP',
                        type: 'error',
                        offset: 60,
                        duration: 1500,
                    });
                    return;
                }
                if (!this.data.isRegister) {
                    this.loading = true;
                    this.$apiPortal.auth.confirmForgotRegister(dataLogin).then((res) => {
                        this.$store.dispatch('auth/setToken', res.data.token);
                        this.$emit('newPassPopup', true);
                        this.dialogVisible = false;
                        this.$emit('OtpPopup', false);
                        this.loading = false;
                    }).catch((e) => {
                        if (e.response) {
                            this.loading = false;
                            return this.$message({
                                message: e.response.data.message,
                                type: 'error',
                                duration: 1500,
                            });
                        }
                    });
                    return;
                }
                this.loading = true;
                this.$apiPortal.auth.confirmRegister(dataLogin).then(async () => {
                    this.$message({
                        message: 'Bạn đã đăng ký thành công',
                        type: 'success',
                        duration: 1500,
                    });
                    this.loading = false;
                    await this.$auth.loginWith('cookie', {
                        data: {
                            phoneNumber: this.data.phoneNumber,
                            password: this.data.password,
                        },
                    });
                    this.dialogVisible = false;
                    this.$emit('OtpPopup', false);
                    if (this.isRedirect) {
                        this.$router.push('/tich-diem-doi-qua');
                    }
                    this.$store.dispatch('mobileDrawer/setShowNav', true);
                }).catch((e) => {
                    if (e.response) {
                        this.loading = false;
                        return this.$message({
                            message: e.response.data.message,
                            type: 'error',
                            duration: 1500,
                        });
                    }
                });
            },
            handleKeyPress(event, value, index) {
                if (this.form[index]?.length > 1) {
                    this.$set(this.form, index, this.form[index].slice(0, 1));
                    this.$set(this.form, index, event?.key);
                }
                if (event.key === 'Backspace') {
                    if (index > 0) {
                        return this.$refs[`inputRefs${index - 1}`][0].focus();
                    }
                }
                if (value) {
                    if (index < this.length - 1) {
                        return this.$refs[`inputRefs${index + 1}`][0].focus();
                    }
                }
            },
            formatTime(seconds) {
                const minutes = Math.floor(seconds / 60);
                const remainingSeconds = seconds % 60;
                return `${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
            },
            startCountdown() {
                setTimeout(() => {
                    if (this.countdown > 0) {
                        this.countdown -= 1;
                        this.startCountdown();
                    }
                }, 1000);
            },
        },
    };
</script>
<style lang="scss">
 .el-dialog {
    max-width: 1200px;
    border-radius: 15px;
    width: 95vw !important;
}
.popup-otp {
        .el-input__inner {
            padding: 0 10px !important;
        }
    }
.label-input-login {
.el-form-item__label{
    color: #292929;
}
    }
    .popup-login{
        .el-dialog__header{
            padding: 20px 20px 0px;
        }
        .el-dialog__title{
            color: #003A03;
            font-size: 20px;
            font-weight: 500;
        }
        .el-dialog__headerbtn .el-dialog__close {
            color: #292929;
            font-size: 18px;

        }
    }
</style>
