<template>
    <div>
        <el-dialog
            :visible.sync="dialogVisible"
            :before-close="close"
            :close-on-click-modal="false"
            title="Xác nhận Số điện thoại"
            custom-class="popup-login"
            top="20vh"
            append-to-body
            destroy-on-close
        >
            <LoadingForm :loading="loading" />
            <div class="bg-[#FFFFFF] rounded-lg p-2">
                <el-form
                    ref="form"
                    :model="form"
                    :rules="rules"
                >
                    <div>
                        <el-form-item class="mb-6 label-input-login" label="Số điện thoại" prop="phoneNumber">
                            <el-input v-model="form.phoneNumber" class="input-login" placeholder="Số điện thoại" />
                        </el-form-item>
                        <el-button class="w-full bg-[#008A07] text-[#FFFFFF] text-sm rounded-lg" @click="handleOtp">
                            Tiếp tục
                        </el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import { validPhone } from '../../utils/form';
    import LoadingForm from './LoadingForm.vue';

    export default {
        components: {
            LoadingForm,
        },
        data() {
            return {
                dialogVisible: true,
                loading: false,
                form: {
                    phoneNumber: null,
                },
                rules: {
                    phoneNumber: [
                        { required: true, message: 'Vui lòng nhập số điện thoại', trigger: 'change' },
                        { validator: validPhone, trigger: 'change' },
                    ],
                },
            };
        },
        methods: {
            close() {
                this.dialogVisible = false;
                this.$emit('phonePopup', false);
            },
            async handleOtp() {
                this.$refs.form.validate(async (valid) => {
                    if (valid) {
                        try {
                            this.loading = true;
                            await this.$apiPortal.auth.otpNewPass({ phoneNumber: this.form.phoneNumber });
                            this.loading = false;
                            this.$emit('OtpPopup', true);
                            this.$emit('phonePopup', false);
                            this.$store.dispatch('auth/setFormRegister', { phoneNumber: this.form.phoneNumber, isRegister: false });
                        } catch (e) {
                            if (e.response) {
                                this.loading = false;
                                return this.$message({
                                    message: e.response.data.message,
                                    type: 'error',
                                    duration: 1500,
                                });
                            }
                        }
                    }
                });
            },
        },
    };
</script>
<style lang="scss">
 .el-dialog {
    max-width: 1200px;
    border-radius: 15px;
    width: 95vw !important;
}
.input-login {
        .el-input__inner {
            border: 1px solid #292929 !important;
        }
    }
.label-input-login {
.el-form-item__label{
    color: #292929;
}
    }
    .popup-login{
        position: relative;
        .el-dialog__header{
            padding: 20px 20px 0px;
        }
        .el-dialog__title{
            color: #003A03;
            font-size: 20px;
            font-weight: 500;
        }
        .el-dialog__headerbtn .el-dialog__close {
            color: #292929;
            font-size: 18px;

        }
    }
</style>
