<template>
    <div class="flex flex-col justify-between min-h-screen ">
        <NavigationMobile class="lg:hidden" @loginPopup="val => val ? $refs.Login.open() : $refs.Login.close()" />
        <Navigation @isPopupNotificationMobile="isPopupMobile = true" />
        <PopupIcon v-if="isShowPopupIcon" class="md:hidden" />
        <Login
            ref="Login"
            class="md:hidden"
            @registerPopup="isShowPopupRegister = true"
            @loginPopup="val => val ? $refs.Login.open() : $refs.Login.close()"
            @phonePopup="isShowConfirmPhone = true"
        />
        <Register
            v-if="isShowPopupRegister"
            @registerPopup="isShowPopupRegister = false"
            @OtpPopup="isShowOtp=true"
            @loginPopup="val => val ? $refs.Login.open() : $refs.Login.close()"
        />
        <ConfirmPhone
            v-if="isShowConfirmPhone"
            @phonePopup="isShowConfirmPhone = false"
            @loginPopup="val => val ? $refs.Login.open() : $refs.Login.close()"
            @OtpPopup="isShowOtp=true"
        />
        <OTP
            v-if="isShowOtp"
            @OtpPopup="isShowOtp=false"
            @newPassPopup="isShowNewPass=true"
        />
        <NewPassword v-if="isShowNewPass" @newPassPopup="isShowNewPass=false" />
        <div class="flex-grow relative flex flex-col" style="background: #f5f5f5;">
            <el-dialog
                :visible.sync="copyStatus"
                class="notifi-voucher"
                :destroy-on-close="true"
            >
                <div class="flex items-center">
                    <div>
                        <svg
                            id="successAnimation"
                            class="animated"
                            xmlns="http://www.w3.org/2000/svg"
                            width="70"
                            height="70"
                            viewBox="0 0 70 70"
                        >
                            <path id="successAnimationResult" fill="#D8D8D8" d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z" />
                            <circle
                                id="successAnimationCircle"
                                cx="35"
                                cy="35"
                                r="24"
                                stroke="#979797"
                                stroke-width="2"
                                stroke-linecap="round"
                                fill="transparent"
                            />
                            <polyline
                                id="successAnimationCheck"
                                stroke="#979797"
                                stroke-width="2"
                                points="23 34 34 43 47 27"
                                fill="transparent"
                            />
                        </svg>
                    </div>
                    <div class="font-semibold">
                        Đã sao chép mã thành công
                    </div>
                </div>
            </el-dialog>
            <Nuxt class="flex-grow" />
        </div>
        <el-backtop class="back-top">
            <div class=" bg-white dark:bg-slate-800 p-2 w-10 h-10 ring-1 ring-slate-900/5 dark:ring-slate-200/20 shadow-lg rounded-full flex items-center justify-center">
                <svg
                    class="w-6 h-6 text-violet-500 rotate-180"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke="#008a07"
                >
                    <path d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                </svg>
            </div>
        </el-backtop>
        <PopupBanner ref="PopupBanner" />
        <BannerDownloadApp />
        <PopupNotificationMobile v-if="isPopupMobile" @isPopupNotificationMobile="isPopupMobile = false" />
        <Footer />
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import Navigation from '~/components/layout/Navigation.vue';
    import NavigationMobile from '~/components/layout/NavigationMobile.vue';
    import Footer from '~/components/layout/Footer.vue';

    import dayjs from 'dayjs';
    import utc from 'dayjs/plugin/utc';
    import timezone from 'dayjs/plugin/timezone';
    import customParseFormat from 'dayjs/plugin/customParseFormat';
    import minMax from 'dayjs/plugin/minMax';
    import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
    import isBetween from 'dayjs/plugin/isBetween';
    import PopupIcon from '@/components/home/PopupIcon.vue';
    import cloneDeep from 'lodash/cloneDeep';
    import BannerDownloadApp from '@/components/shared/BannerDownloadApp.vue';
    import PopupBanner from '../components/PopupBanner.vue';
    import PopupNotificationMobile from '../components/loyalty/popupNotification/PopupNotificationMobile.vue';
    import Login from '../components/signIn/Login.vue';
    import Register from '../components/signIn/Register.vue';
    import OTP from '../components/signIn/OTP.vue';
    import ConfirmPhone from '../components/signIn/ConfirmPhone.vue';
    import NewPassword from '../components/signIn/NewPassword.vue';

    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(isSameOrBefore);
    dayjs.extend(customParseFormat);
    dayjs.extend(minMax);
    dayjs.extend(isBetween);

    const title = 'Bloggiamgia';
    const defaultMeta = [
        {
            hid: 'robots',
            name: 'robots',
            content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1',
        },
        {
            property: 'og:locale',
            content: 'vi_VN',
        },
        {
            hid: 'og:type',
            property: 'og:type',
            content: 'website',
        },
        {
            hid: 'og:site_name',
            name: 'og:site_name',
            content: 'Blog Giảm Giá',
        },
        {
            hid: 'og:title',
            property: 'og:title',
            content: title,
        },
        {
            hid: 'og:image',
            property: 'og:image',
            content: '/images/facebook-og-image.png',
        },
    ];

    const link = [
        {
            rel: 'stylesheet',
            href: 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap',
        },
    ];

    const script = [];

    export default {
        middleware: ['user'],
        components: {
            Navigation,
            NavigationMobile,
            Footer,
            PopupIcon,
            PopupBanner,
            PopupNotificationMobile,
            Login,
            Register,
            OTP,
            ConfirmPhone,
            NewPassword,
            BannerDownloadApp,
        },
        data() {
            return {
                isShowPopupIcon: false,
                showBanner: false,
                timer: null,
                isPopupMobile: false,
                isShowPopupRegister: false,
                isShowOtp: false,
                isShowConfirmPhone: false,
                isShowNewPass: false,
            };
        },
        computed: {
            ...mapState('metaTag', ['metaTags']),
            ...mapState('voucher', ['copyStatus']),
        },
        watch: {
            '$route.path': {
                handler(to, from) {
                    if (to === from) return;
                    this.getBanner();
                },
                deep: true,
            },
        },
        mounted() {
            this.isShowPopupIcon = window.innerWidth <= 768;
            const anchors = document.getElementsByTagName('a');
            for (let i = 0; i < anchors.length; i += 1) {
                if (!anchors[i].href?.includes(process.env.APP_URL) && anchors[i].href?.includes('http')) {
                    anchors[i].setAttribute('target', '_blank');
                } else if (anchors[i].attributes.external !== undefined) {
                    anchors[i].setAttribute('target', '_blank');
                } else {
                    anchors[i].setAttribute('target', '');
                }
            }
            this.getBanner();
            if (this.$auth.loggedIn) {
                const userData = this.$auth.user;
                this.$store.dispatch('auth/setUser', userData);
            }
        },
        unmounted() {
            if (this.timer) clearTimeout(this.timer);
        },
        methods: {
            async getBanner() {
                try {
                    let params = { url: window.location?.pathname !== '/' ? window.location?.pathname : '/home' };

                    if (this.$route.path === '/' && this.$route?.query?.previewId) {
                        params = { id: this.$route?.query?.previewId };
                    }
                    const configs = await this.$api.intentPopup.getBanners(params);
                    configs?.data?.forEach((config) => {
                        if (config.limitViewTime) {
                            this.timer = setTimeout(() => {
                                this.$refs.PopupBanner.open(config, !!params.id);
                            }, parseFloat(config.limitViewTime) * 1000);
                        }
                        if (config.limitScroll) {
                            window.onscroll = () => {
                                const scrollPer = document.documentElement.scrollTop / (document.documentElement.scrollHeight - document.documentElement.clientHeight);
                                if (scrollPer < parseFloat(config.limitScroll) / 100) {
                                    return;
                                }
                                this.$refs.PopupBanner.open(config, !!params.id);
                            };
                        }
                    });
                } catch (e) { console.error(e); }
            },
            openOrderForm(product, quantity) {
                this.$refs.FormOrder.open(product, quantity);
            },
            openFinishOrder(type, order, typeError) {
                if (type === 'success') {
                    this.$refs.FormOrder.close();
                }
                this.$refs.FinishOrderDialog.open(type, order, typeError);
            },
        },
        head() {
            const meta = cloneDeep(defaultMeta);
            if (this.metaTags) {
                this.metaTags.tags.forEach((tag) => {
                    const foundIndexName = meta.findIndex((x) => x.name === tag.name);
                    const foundIndexProp = meta.findIndex((x) => x.property === tag.name);
                    if (foundIndexName >= 0) {
                        meta[foundIndexName] = {
                            hid: tag.name,
                            name: tag.name,
                            content: tag.content,
                        };
                    } else if (foundIndexProp >= 0) {
                        meta[foundIndexProp] = {
                            hid: tag.name,
                            property: tag.name,
                            content: tag.content,
                        };
                    } else {
                        meta.push(tag);
                    }
                });
            }
            return {
                htmlAttrs: {
                    lang: 'vi',
                },
                title: this.metaTags ? this.metaTags.title : 'Trang chủ',
                meta,
                link,
                script,
                __dangerouslyDisableSanitizers: ['script'],
            };
        },
    };
</script>

<style lang="scss" src="@/assets/scss/index.scss">
</style>
