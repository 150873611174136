const state = () => ({
    metaTags: null,
});

const actions = {
    async fetch({ commit }, params) {
        try {
            const metaTags = await this.$api.metaTags.fetch(params);
            commit('setMetaTags', metaTags.metaTag);
        } catch (e) {
            console.log(e.message);
        }
    },
};

const mutations = {
    setMetaTags(state, metaTags) {
        state.metaTags = metaTags;
    },
};

export {
    state,
    actions,
    mutations,
};
