const state = () => ({
    keyword: '',
});

const getters = {
    getKeyword: (state) => state.keyword,
};

const actions = {
    setKeyword({ commit }, keyword) {
        commit('setKeyword', keyword);
    },
};

const mutations = {
    setKeyword(state, keyword) {
        state.keyword = keyword;
    },
};

export {
    state,
    getters,
    actions,
    mutations,
};
