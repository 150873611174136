export const TRASH_STATUS = {
    DELETED: {
        label: 'Deleted',
        slug: 'deleted',
    },
    ACTIVE: {
        label: 'Active',
        slug: 'active',
    },
};

export const ADD_LIVE_ERROR = {
    ERROR: 'error',
    ERROR_LIVE: 'error-live',
    ERROR_SESSION: 'error-session',
    ERROR_LINK: 'error-link',
    NO_LIVESTREAM: 'no-livestream',
    ERROR_SYSTEM: 'error-system',
    ERROR_NOT_FOUND: 'error-not-found',
    ERROR_SKIP_PRODUCT: 'error-skip-product',
};

export const ADD_VIDEO_TYPE_RESPONSE = {
    ERROR: 'error',
    SUCCESS: 'success',
};

export const FLASH_SALE_SHOPEE_LINK = [
    'https://s.shopee.vn/an_redir?origin_link=https%3A%2F%2Fshopee.vn%2Fflash_sale&utm_medium=affiliates&affiliate_id=17350340019&sub_id=---quangcaobaohien',
    'https://shopee.vn/m/khung-gio-san-sale?uls_trackid=50arah8301d9&utm_campaign=-&utm_content=---quangcaobaohien&utm_medium=affiliates&utm_source=an_17350340019&utm_term=bh8m61yt37tj',
];

export const walletShopee = 'https://s.shopee.vn/an_redir?origin_link=https%3A%2F%2Fshopee.vn%2Fuser%2Fvoucher-wallet&utm_medium=affiliates&affiliate_id=17350340019&sub_id=---quangcaobaohien';

export const sendoLinkRedirect = 'https://www.sendo.vn/su-kien/ma-giam-gia';
