<template>
    <div>
        <div class="w-full h-[84px] bg-[#008A07] rounded-bl-2xl rounded-br-2xl justify-center items-center gap-[3px] inline-flex">
            <div class="h-[52px] w-full max-w-[1200px] justify-between items-center flex px-4">
                <div class="justify-start items-center gap-2 flex">
                    <img class="w-[52px] h-[52px]" src="/images/logo-app.png">
                    <div class="flex-col justify-center items-start gap-1 inline-flex">
                        <div class="text-white text-lg md:text-xl font-semibold leading-normal">
                            Nô Tì
                        </div>
                        <div class="text-[#ffffff4d] text-lg md:text-xl font-semibold leading-normal">
                            Trợ lý săn Sale
                        </div>
                    </div>
                </div>
                <img class="h-full" src="/images/gift.png">
            </div>
        </div>
        <Nuxt class="flex-grow" />
        <div class="w-full bg-[#008A07] flex justify-center">
            <div class="w-full max-w-[1200px] px-4 pt-3 pb-[136px] md:pb-[160px] flex-col justify-start items-start gap-4 inline-flex">
                <div class="self-stretch justify-between items-center inline-flex">
                    <div class="grow shrink basis-0 text-white text-sm md:text-base font-bold">
                        CÔNG TY TNHH PIGGI
                    </div>
                    <div class="justify-center items-start gap-3 flex">
                        <a href="https://m.me/j/AbbS7y_rYAYkeyWe" target="_blank" rel="noopener noreferrer"><img class="w-6 h-6 md:w-8 md:h-8" src="/images/logo-face.svg"></a>
                        <a href="https://t.me/nghiensandeal" target="_blank" rel="noopener noreferrer"><img class="w-6 h-6 md:w-8 md:h-8" src="/images/telegram.png"></a>
                        <a href="https://m.me/j/AbY5zszkU_mcB-rB" target="_blank" rel="noopener noreferrer"><img class="w-6 h-6 md:w-8 md:h-8" src="/images/logo-mess.svg"></a>
                        <a href="https://zalo.me/g/zbuyhg226" target="_blank" rel="noopener noreferrer"><img class="w-6 h-6 md:w-8 md:h-8" src="/images/zalo.png"></a>
                    </div>
                </div>
                <div class="h-[156px] flex-col justify-start items-start gap-3 flex">
                    <div class="self-stretch justify-start items-start gap-1.5 inline-flex">
                        <div class="text-white text-sm md:text-base font-semibold">
                            Email:
                        </div>
                        <div class="text-white text-sm md:text-base font-normal">
                            contact@piggi.vn
                        </div>
                    </div>
                    <div class="self-stretch justify-start items-start gap-1.5 inline-flex">
                        <div class="text-white text-sm md:text-base font-semibold">
                            Số điện thoại:
                        </div>
                        <div class="text-white text-sm md:text-base font-normal">
                            0889966741
                        </div>
                    </div>
                    <div class="self-stretch justify-start items-start gap-1.5 inline-flex">
                        <div class="text-white text-sm md:text-base font-semibold">
                            Giấy CNĐKDN:
                        </div>
                        <div class="w-[82px] text-white text-sm md:text-base font-normal">
                            3603827194
                        </div>
                    </div>
                    <div class="self-stretch">
                        <span class="text-white text-sm md:text-base font-semibold">Địa chỉ ĐKDN:</span>
                        <span class="text-white text-sm md:text-base font-normal">Số 88 Thanh Lương 10, Phường Hoà Xuân, Quận Cẩm Lệ, Thành phố Đà Nẵng, Việt Nam</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    const link = [
        {
            rel: 'stylesheet',
            href: 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap',
        },
    ];
    export default {
        head() {
            return {
                link,
            };
        },

    };
</script>
<style lang="scss" src="@/assets/scss/index.scss">
</style>
