export default async ({
    store, route, $axios, redirect,
}) => {
    await store.dispatch('metaTag/fetch', { slug: route.path.substring(1) || 'home' });
    try {
        const redirectUrl = await $axios(`/b/CustomRedirect/byPath?path=${route.path.substring(1)}`);
        if (redirectUrl?.data?.redirect) {
            redirect(redirectUrl.data.redirect.redirectTo);
        }
    } catch (error) {
        console.log(error.message);
    }
    return 1;
};
