const state = () => ({
    suppliers: null,
});

const actions = {
    async getSuppliers({ commit }, params) {
        const { data } = await this.$api.suppliers.getSuppliers(params);
        commit('setSuppliers', data.data);
    },
};

const mutations = {
    setSuppliers(state, suppliers) {
        state.suppliers = suppliers;
    },
};

export {
    state,
    actions,
    mutations,
};
