<template>
    <div
        id="slide"
        ref="slide"
        class="relative overflow-auto transition ease-in-out delay-150 duration-300 "
        :class="`${optionsCpt.scroll.showBottomScroll ? '' : 'scrollbar-hide'} py-[${optionsCpt.spacing}px]`"
        @scroll="onscroll"
    >
        <div v-if="optionsCpt.skeleton.fullWidth" class="w-full flex overflow-hidden bg-[#efefef] relative after:absolute after:h-full after:w-[20%] after:top-0 rounded-md skeleton">
            <nuxt-img
                src="#"
                alt=""
                class="block invisible w-full"
                :class="`${!loading ? 'invisible h-0 overflow-hidden' :'visible'}`"
                :width="optionsCpt.skeleton.scale[0]"
                :height="optionsCpt.skeleton.scale[1]"
            />
        </div>
        <div
            v-else
            ref="skeletonRef"
            class="w-[max-content] flex overflow-hidden"
            :style="{gap: optionsCpt.spacing + 'px'}"
            :class="`${!loading ? 'invisible h-0 overflow-hidden' :'visible'}`"
        >
            <div
                :class="skeletonClass"
                class="bg-[#efefef] relative overflow-hidden after:absolute after:h-full after:w-[20%] after:top-0 rounded-md skeleton"
            />
        </div>

        <div
            ref="slidesContainer"
            class="flex w-[max-content] relative after:bg-transparent after:absolute after:h-full after:top-0 after:left-0 after:right-0 after:opacity-50"
            :class="`${loading ? 'invisible h-0 overflow-hidden w-full' :'visible'} ${isScroll ? 'after:z-10' : 'after:-z-10'}`"
            :style="{gap: optionsCpt.spacing + 'px', ...(optionsCpt.totalWidth ? {width: optionsCpt.totalWidth} : {})}"
            @mousedown="mousedown"
            @mouseup="mouseup"
            @mousemove="mousemove"
            @mouseleave="mouseleave"
        >
            <slot />
        </div>
    </div>
</template>
<script>
    import { defu } from 'defu';
    import _debounce from 'lodash/debounce';

    const defaultOptions = {
        isLoop: false,
        scroll: {
            scrollFast: 1,
            showBottomScroll: false,
        },
        autoPlay: {
            isAuto: false,
            timeStep: 4000,
            slideStep: 1,
            direction: 'ltr', // values: 'ltr' : 'rtl'
        },
        spacing: 12,
        skeleton: {
            fullWidth: false,
            scale: [1, 1],
        },
        totalWidth: null,
    };
    export default {
        props: {
            option: {
                type: Object,
            },
            loading: Boolean,
            skeletonClass: String,
        },
        data() {
            return {
                position: null,
                isDown: false,
                autoPlay: null,
                dummy_x: null,
                slideActive: 0,
                isScroll: false,
                isDuplicateLoop: false,
            };
        },
        computed: {
            optionsCpt() {
                return defu(this.option, defaultOptions);
            },
            slideChildrenRef() {
                return this.$refs.slidesContainer.children;
            },
            slideBounding() {
                return this.$refs.slide.getBoundingClientRect();
            },
        },
        watch: {
            loading() {
                if (this.loading) {
                    this.initSkeleton();
                    return;
                }
                this.initSlideAmount();
            },
        },
        unmounted() {
            window.removeEventListener('resize', _debounce(() => {
                this.initSlideAmount();
                if (this.loading) { this.initSkeleton(); }
            }, 500));
        },
        mounted() {
            if (this.loading) { this.initSkeleton(); }
            this.initSlideAmount();
            window.addEventListener('resize', _debounce(() => {
                this.initSlideAmount();
                if (this.loading) { this.initSkeleton(); }
            }, 500));

            this.autoPlaySlide();
        },
        beforeDestroy() {
            clearInterval(this.autoPlay);
            window.removeEventListener('resize', _debounce(() => {
                this.initSlideAmount();
                if (this.loading) { this.initSkeleton(); }
            }, 500));
        },
        methods: {
            mousedown(event) {
                event.preventDefault();
                this.isDown = true;
                this.stopAutoPlay = true;
                this.position = event.pageX - this.$refs.slide.offsetLeft;
            },
            mouseup(event) {
                event.preventDefault();
                this.isScroll = false;
                this.isDown = false;
            },
            mousemove(event) {
                event.preventDefault();
                if (!this.isDown || event.pageX === this.position) return;
                this.isScroll = true;
                const walk = (event.movementX) * this.optionsCpt.scroll.scrollFast; // scroll-fast
                this.scrollSlide(walk);
            },
            mouseleave() {
                this.isDown = false;
                this.isScroll = false;
            },
            scrollSlide(walk, smooth = false) {
                // Trường hợp slide chạy từ phải qua trái walk < 0 và ngược lại
                if (this.optionsCpt.isLoop && walk > 0 && this.$refs.slide.scrollLeft - walk <= 0) {
                    this.$refs.slide.scrollLeft += (this.$refs.slidesContainer.scrollWidth / 2);
                    this.$refs.slide.scrollTo({ left: this.$refs.slide.scrollLeft - walk, behavior: smooth ? 'smooth' : 'instant' });
                    return;
                }

                const lastItemBounding = this.slideChildrenRef[this.slideChildrenRef.length - 1].getBoundingClientRect();
                if (this.optionsCpt.isLoop && walk < 0 && lastItemBounding.right + walk <= this.slideBounding.right) {
                    this.$refs.slide.scrollLeft -= (this.$refs.slidesContainer.scrollWidth / 2);
                    this.$refs.slide.scrollTo({ left: this.$refs.slide.scrollLeft - walk, behavior: smooth ? 'smooth' : 'instant' });
                    return;
                }
                this.$refs.slide.scrollTo({ left: this.$refs.slide.scrollLeft - walk, behavior: smooth ? 'smooth' : 'instant' });
            },
            autoPlaySlide() {
                const { autoPlay, spacing } = this.optionsCpt;
                if (!autoPlay.isAuto) return;
                this.autoPlay = setInterval(() => {
                    if (this.loading || this.isDown) return;
                    const slideActive = this.$refs.slide.scrollLeft / (this.slideChildrenRef[0]?.clientWidth + spacing);
                    const slideActiveBounding = this.slideChildrenRef[Math.floor(slideActive)]?.getBoundingClientRect();
                    if (autoPlay.direction === 'rtl') { // Trường hợp slide chạy từ phải sang trái
                        const diff = slideActiveBounding.left - this.slideBounding.left;
                        const times = Math.floor(slideActive) === slideActive ? autoPlay.slideStep : (autoPlay.slideStep - 1);
                        const nextSlideWidth = (this.slideChildrenRef[0]?.clientWidth + spacing) * times;
                        const walk = diff - nextSlideWidth;
                        this.scrollSlide(-walk, true);
                        return;
                    }
                    // Trường hợp slide chạy từ trái sang phải
                    const diff = slideActiveBounding.right - this.slideBounding.left;
                    const times = Math.floor(slideActive) !== slideActive ? autoPlay.slideStep : (autoPlay.slideStep - 1);
                    const nextSlideWidth = (this.slideChildrenRef[0]?.clientWidth + spacing) * times;
                    const walk = diff + spacing + nextSlideWidth;
                    this.scrollSlide(-walk, true);
                }, autoPlay.timeStep);
            },
            initSlideAmount() { // Sử dụng cho trường hợp slide vòng lặp vô hạn
                // Thêm số lượng slide để độ dài slide lớn hơn width của container và số lần lặp lại slide phải là số chẵn
                let times = this.$refs.slide?.clientWidth / this.$refs.slidesContainer?.clientWidth;
                if (!this.optionsCpt.isLoop || (times <= 0.5 && this.isDuplicateLoop)) return;

                times = Math.ceil(times);
                times = times % 2 ? times : times + 1;
                const html = this.$refs.slidesContainer.innerHTML;
                for (let i = 0; i < times; i += 1) {
                    this.$refs.slidesContainer.insertAdjacentHTML('beforeEnd', html);
                }
                this.isDuplicateLoop = true;
            },
            initSkeleton() {
                if (this.optionsCpt.skeleton.fullWidth) return;
                const times = Math.ceil(this.$refs.slide?.clientWidth / this.$refs.skeletonRef?.clientWidth);
                if (times === Infinity || times <= 1) return;
                const html = this.$refs.skeletonRef.innerHTML;
                for (let i = 0; i < times; i += 1) {
                    this.$refs.skeletonRef.insertAdjacentHTML('beforeEnd', html);
                }
            },
            onscroll() {
                const lastItemBounding = this.slideChildrenRef[this.slideChildrenRef.length - 1].getBoundingClientRect();
                if (this.optionsCpt.isLoop && lastItemBounding.right === this.slideBounding.right) {
                    this.$refs.slide.scrollLeft -= (this.$refs.slidesContainer.scrollWidth / 2);
                    return;
                }
                if (this.optionsCpt.isLoop && this.$refs.slide.scrollLeft === 0) {
                    this.$refs.slide.scrollLeft += (this.$refs.slidesContainer.scrollWidth / 2);
                }
            },
        },
    };
</script>
<style scoped>
::-webkit-scrollbar {
width: 4px;
height: 4px;
}
::-webkit-scrollbar:hover {
width: 6px;
height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #cacacc;
height: 6px;
}
::-webkit-scrollbar-thumb {
background: #e5e7eb;
border-radius: 10px;
}
.scrollbar-hide::-webkit-scrollbar {
    -ms-overflow-style: none; /* IE 11 */
    width: 0;
    height: 0;
}
.scrollbar-hide {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.skeleton:after{
left: -100px;
content: "" !important;
background: linear-gradient(to right, transparent, #ffffff70, transparent);
animation: reflect 1000ms ease-out infinite;
}
@keyframes reflect {
to {
  left: calc(100% + 100px);
}
}
</style>
