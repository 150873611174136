<template>
    <div v-if="iconPopup" class="fixed bottom-24 right-2 z-[2] cursor-pointer">
        <div class="absolute right-0 -top-[10px] bg-white rounded-full w-5 h-5 flex items-center justify-center" @click="closeIcon">
            <i class="el-icon-circle-close text-2xl leading-6 text-[#F06574] font-semibold" />
        </div>
        <a :href="iconPopup.url" :target=" isHostOrigin ? '': '_blank'">
            <nuxt-img :src="toImage(iconPopup.image, 'thumbnail')" alt="" class="w-[15vw] min-w-[80px]" />
        </a>
    </div>
</template>
<script>
    import dayjs from 'dayjs';
    import _sample from 'lodash/_arraySample';
    import _deepClone from 'lodash/cloneDeep';
    import { image as toImage } from '~/utils/url';

    export default {
        async fetch() {
            const { data } = await this.$api.popupIcons.getPopupIcons();
            this.popupIcons = data;
            const iconClosed = _deepClone(JSON.parse(localStorage.getItem('iconClosed') || '{}'));
            const popupIcons = this.popupIcons.filter((val) => {
                if (iconClosed[val.id] && dayjs(iconClosed[val.id], 'DD-MM-YYYY HH:mm:ss').isAfter(dayjs(), 's')) {
                    return false;
                }
                delete iconClosed[val.id];
                return true;
            });
            localStorage.setItem('iconClosed', JSON.stringify(iconClosed || {}));

            this.iconPopup = _sample(popupIcons, 1);
        },
        data() {
            return {
                popupIcons: [],
                iconPopup: null,
            };
        },
        computed: {
            isHostOrigin() {
                return this.iconPopup?.url?.includes(window.location.origin);
            },

        },

        methods: {
            toImage,
            closeIcon() {
                let iconClosed = _deepClone(JSON.parse(localStorage.getItem('iconClosed') || '{}'));
                iconClosed = { ...iconClosed, [this.iconPopup?.id || '']: dayjs().add(12, 'hour').format('DD-MM-YYYY HH:mm:ss') };
                localStorage.setItem('iconClosed', JSON.stringify(iconClosed || {}));
                this.iconPopup = null;
            },
        },
    };
</script>
