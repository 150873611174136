<template>
    <div style="background-color:#fef8f2">
        <div class="bg-white mt-6 md:mt-12 rounded-t-3xl">
            <div class="pt-5 pb-2 pl-5 md:pr-5 max-w-1200 lg:mx-auto">
                <div class="text-base font-semibold mb-3 md:mb-5 lg:text-2xl">
                    Tìm mã theo Sàn
                </div>
                <div>
                    <Slider
                        v-if="supplierStep && supplierStep.length > 0"
                        class="pb-3"
                        :option="swiperOption"
                    >
                        <div v-for="(arr, index) in supplierStep" :key="index" class="w-[120px] md:w-[126px] lg:w-[144px] xl:w-[173px] ">
                            <div v-for="supplier in arr" :key="supplier.id">
                                <nuxt-link target="_blank" :to="getLink(supplier?.slug)" :external="true">
                                    <div
                                        :style="{ background: supplier.colorCode || '#ff5723' }"
                                        class="rounded-2xl px-2 py-3 mb-3 md:mb-6 h-36 md:h-48 xl:h-56 flex flex-col justify-center"
                                    >
                                        <nuxt-img
                                            class="mx-auto w-10 h-10 sm:w-12 sm:h-12 xl:mb-3 "
                                            :src="toPortalImage(supplier.avatar)"
                                            alt="supplier"
                                        />
                                        <div class="mx-auto text-center text-[12px] xl:text-lg">
                                            <h2 class="font-semibold text-white">
                                                <p>Mã Giảm Giá</p> {{ supplier.name }}
                                            </h2>
                                        </div>
                                    </div>
                                </nuxt-link>
                            </div>
                        </div>
                    </Slider>
                    <div v-else class="grid grid-cols-6 gap-y-6 w-full">
                        <div v-for="i in 12" :key="i" class="animate-pulse space-y-4 flex flex-col justify-center  items-center">
                            <div class="flex justify-center align-middle space-x-4">
                                <div class="bg-gray-200 h-48 w-40 rounded-md" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _chunk from 'lodash/chunk';
    import { image as toImage, toPortalImage } from '~/utils/url';
    import Slider from '@/components/Slider.vue';

    export default {
        components: { Slider },
        props: {
            suppliers: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                searchKey: '',
                swiperOption: {
                    spacing: 18,
                },
            };
        },
        computed: {
            supplierStep() {
                return _chunk(this.suppliers, 2);
            },
        },

        methods: {
            toImage,
            toPortalImage,
            getLink(slug) {
                if (slug === 'shopee') {
                    return '/shopee';
                } if (slug === 'lazada') {
                    return '/lazada';
                }
                return `/ma-giam-gia/${slug}`;
            },
        },
    };
</script>

<style lang="sass">
    .offer-item
        min-width: 144px
</style>
