import _without from 'lodash/without';

const state = () => ({
    selectedCate: [],
});

const actions = {
    setSelectedCate({ commit }, selectedCate) {
        commit('setSelectedCate', selectedCate);
    },
    addSelectedCate({ commit }, newItem) {
        commit('addSelectedCate', newItem);
    },
    removeSelectedCate({ commit }, oldItem) {
        commit('removeSelectedCate', oldItem);
    },
};

const mutations = {
    setSelectedCate(state, selectedCate) {
        state.selectedCate = selectedCate;
    },
    addSelectedCate(state, newItem) {
        if (!state.selectedCate.includes(newItem)) {
            state.selectedCate.push(newItem);
        }
    },
    removeSelectedCate(state, oldItem) {
        state.selectedCate = _without(state.selectedCate, oldItem);
    },
};

export {
    state,
    actions,
    mutations,
};
