<template>
    <div
        :id="hotDealProduct.id"
        :key="hotDealProduct.id"
        v-click-outside="hideDetail"
        class="h-full min-h-[140px] relative ticket-wrap flex justify-center"
    >
        <div
            v-show="!showDetail"
            class="rounded-lg w-full"
            :class="`${!showDetail ? 'ticket' : ''} `"
        >
            <div
                class="flex flex-col justify-between cursor-pointer pt-3 pb-0.5 mx-auto relative h-full"
                @click="showDetail = true"
                @click.stop="1"
            >
                <img class="absolute right-0 top-0 h-[40%]" src="/images/hot-tag.svg" height="40%">
                <div class="flex w-full">
                    <div class="logo-supplier ml-5 items-center w-[34%] max-w-[100px]">
                        <div
                            v-if="!hotDealProduct.avatar"
                            class="flex items-center justify-center w-32 h-16"
                            style=" width: 130px;
                                    height: 60px;
                                    background: #e8e8e8;"
                        >
                            <i class="el-icon-picture" />
                        </div>
                        <img v-else class="rounded-md object-contain w-32 h-full" :src="toImage(hotDealProduct.avatar, 'thumbnail')">
                    </div>
                    <div class="pl-3 pr-5 min-h-[92px] w-full">
                        <div class="mini-title-supplier font-semibold text-left relative">
                            <img class="rounded-full p-1 right-0 top-0 absolute" style="background: #ed262a; width: 40px;height: 40px;" src="images/image-hot-deal.svg">
                        </div>
                        <div>
                            <div class="mini-title-supplier font-semibold text-left relative">
                                <span />
                            </div>
                            <div class="max-[280px]:text-[11px] w-4/5 md:text-xs mb-1 md:mb-0 flex items-center">
                                <div class="text-sm font-semibold">
                                    {{ hotDealProduct.title }}
                                </div>
                                <div class="font-bold text-lg md:text-2xl ml-1 hidden" style="color: #008A07">
                                    <span class="underline decoration-1">
                                        {{ salePrice.discount }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="max-[280px]:text-[11px] md:text-xs">
                            <span class=" font-normal  text-[#7A8898] text-xs ml-1 ">
                                {{ hotDealProduct.subTitle }}
                            </span>
                            <span class="text-[#008A07] text-base font-bold ml-1  ">{{ salePrice.price }}K</span>
                        </div>
                        <div class="flex items-center mb-0">
                            <span class="italic text-left text-xs font-semibold name_product">
                                {{ hotDealProduct.description }}
                            </span>
                        </div>
                        <div v-if="timeFlashSale" class="max-[280px]:text-[11px] md:text-xs time-flash-sale-reponsive">
                            <span>Kết thúc: </span>
                            <span class="text-[#FE9800] font-normal ">{{ timeFlashSale }} </span>
                        </div>
                    </div>
                </div>
                <div class="flex mini-action justify-between pr-2 mb-2">
                    <div class="flex expried-date justify-center min-w-[106px] pl-2 mr-2">
                        <div v-if="timeFlashSale" class="max-[280px]:text-[11px] w-max md:text-xs text-center time-flash-sale">
                            <span>Kết thúc: </span>
                            <span class="text-[#FE9800] font-normal ">{{ timeFlashSale }} </span>
                        </div>
                    </div>
                    <div class="flex w-full items-center justify-between pr-1">
                        <div class="flex w-full justify-between items-center gap-2 mt-1">
                            <div class="hashtag flex border-2 border-solid ml-2 sm:ml-1 px-2 rounded items-center gap-1 label-flash-sale" :class="timeFlashSale ? 'border-green-600': ''">
                                <p class="font-sans italic text-sm p-0.5" :style="{color: timeFlashSale ? '#008A07': '#dededc'}">
                                    {{ timeFlashSale ? 'Flashsale':'Hết Flashsale' }}
                                </p>
                            </div>
                            <div class="flex cursor-pointer">
                                <el-button
                                    type="text"
                                    style="background: #FF9900; width: 105px; color: #EDFFEE; height: 28px; display: flex;"
                                    class="font-semibold rounded-lg px-1 py-1 transition-all duration-300 text-center text-xs overflow-hidden overflow-ellipsis flex items-center justify-center"
                                    @click="onBuyNow"
                                    @click.stop="1"
                                >
                                    <span>Mua Ngay</span>
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-show="showDetail"
            class="bg-white relative mx-auto pt-3 pb-2 px-3 mb-2 sm:absolute z-40 overflow-hidden rounded-lg shadow-lg border w-full"
            @click="showDetail = false"
        >
            <div class="flex flex-col">
                <div
                    v-if="!hotDealProduct && !hotDealProduct.images[0]"
                    class="flex items-center justify-center h-16"
                    style="height: 100px; background: #e8e8e8;"
                >
                    <i class="el-icon-picture" />
                </div>
                <img v-else-if="!hotDealProduct && hotDealProduct.images[0]" :src="toImage(hotDealProduct.images[0], 'thumbnail')">
                <p v-if="hotDealProduct.note" class="font-semibold capitalize mt-2 whitespace-pre-line">
                    <span>{{ hotDealProduct.note }}</span>
                </p>
                <div class="flex mini-action justify-between pt-2">
                    <!-- <div v-if="timeFlashSale" class="flex expried-date justify-center min-w-[106px] pl-2 mr-2">
                        <div class="max-[280px]:text-[11px] w-max md:text-xs text-center time-flash-sale">
                            <span>Kết thúc: </span>
                            <span class="text-[#FE9800] font-normal ">{{ timeFlashSale }} </span>
                        </div>
                    </div> -->
                    <div class="flex w-full items-center justify-between">
                        <div class="flex w-full justify-between items-center gap-2">
                            <div class="hashtag flex border-2 px-2 py-1 border-solid rounded-lg items-center gap-1 label-flash-sale" :class="timeFlashSale ? 'border-green-600': ''">
                                <p class="font-sans italic text-sm" :style="{color: timeFlashSale ? '#008A07': '#dededc'}">
                                    {{ timeFlashSale ? 'Flashsale':'Hết Flashsale' }}
                                </p>
                            </div>
                            <div class="flex cursor-pointer">
                                <el-button
                                    type="text"
                                    style="background: #FF9900; width: 105px; color: #EDFFEE; display: flex;"
                                    class="font-semibold font-sans px-2 py-1 transition-all duration-300 text-center text-sm overflow-hidden overflow-ellipsis flex items-center justify-center rounded-lg"
                                    @click="onBuyNow"
                                    @click.stop="1"
                                >
                                    <span>Mua Ngay</span>
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import dayjs from 'dayjs';
    import { image as toImage } from '@/utils/url';
    import ClickOutside from 'vue-click-outside';

    export default {
        directives: {
            ClickOutside,
        },
        props: {
            hotDealProduct: {
                type: Object,
            },
        },
        data() {
            return {
                timeEnd: '',
                timer: null,
                showDetail: false,
            };
        },

        computed: {
            timeFlashSale() {
                if (!this.timeEnd || this.timeEnd === 'End') return null;

                const {
                    hours, minutes, seconds,
                } = this.timeEnd;

                return `${String(hours || 0).padStart(2, '0')}:${String(minutes || 0).padStart(2, '0')}:${String(seconds || 0).padStart(2, '0')}`;
            },
            salePrice() {
                const { price } = this.hotDealProduct;
                const discountValue = parseFloat(this.hotDealProduct.discountValue || 0);
                return discountValue < 1 ? { price: discountValue * price * 10, discount: `${discountValue * 100}%` } : { price: (price - discountValue) / 1000, discount: `${discountValue / 1000}k` };
            },
        },
        mounted() {
            if (this.hotDealProduct?.endAt && dayjs().isBetween(dayjs(this.hotDealProduct?.startedAt), dayjs(this.hotDealProduct?.endAt))) {
                this.timer = setInterval(() => {
                    const formattedEndTime = dayjs(this.hotDealProduct.endAt).format('YYYY-MM-DD HH:mm:ss');
                    const total = Date.parse(formattedEndTime)
                        - Date.parse(dayjs().format('YYYY-MM-DD HH:mm:ss'));

                    const seconds = Math.floor((total / 1000) % 60);
                    const minutes = Math.floor((total / 1000 / 60) % 60);
                    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
                    const days = Math.floor(total / (1000 * 60 * 60 * 24));

                    if (hours < 0 || minutes < 0 || seconds < 0) {
                        this.timeEnd = 'End';
                        clearInterval(this.timer);
                        return;
                    }

                    this.timeEnd = {
                        total,
                        days,
                        hours,
                        minutes,
                        seconds,
                    };
                }, 1000);
            }
        },
        unmounted() {
            clearInterval(this.timer);
        },
        methods: {
            dayjs,
            toImage,
            hideDetail() {
                if (this.showDetail) {
                    this.showDetail = false;
                }
            },
            onBuyNow() {
                window.open(this.hotDealProduct.externalLink, '_blank', 'noreferrer,noopener');
            },
        },
    };
</script>

<style lang="scss" scoped>
.price-old{
    text-decoration:line-through
}
.name_product{
    overflow: hidden;
  text-overflow:
      ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.time-flash-sale-reponsive{
    display: none;
}
.time-flash-sale{
    display: block;
}

@media (max-width: 390px) {
    .label-flash-sale{
    margin-left: 4px !important;
}
.time-flash-sale-reponsive {
    display: block;
  }
  .time-flash-sale{
    display: none;
}
}

.title-voucher {
  overflow: hidden;
  text-overflow:
      ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.mini-title-supplier {
  display: none;
}
</style>
