<template>
    <div style="z-index: 999;">
        <div class="flex justify-between items-center w-full px-5 py-2 fixed" style="background-color:#fff" @click="handleFormHiddenNav">
            <div class="flex">
                <nuxt-link to="/">
                    <nuxt-img
                        src="/images/logo.svg"
                        alt="logo"
                        style="width:150px; height:42px"
                    />
                </nuxt-link>
            </div>
            <div class="text-base" @click="handleFormHiddenNav(showNav)">
                <img
                    src="/images/icons/category.png"
                    width="24"
                    alt="mobile nav"
                    height="24"
                >
            </div>
        </div>
        <div
            :class="{'openNav':showNav}"
            class="mobileNavWrapper overflow-x-hidden fixed top-0 right-0 min-h-screen w-full bg-black bg-opacity-60"
            style="z-index: 9999;"
            @click="showNav = !showNav"
        >
            <div :class="{'slideNav':showNav}" class="mobileNav max-w-xs bg-white rounded-tl-3xl min-h-screen w-11/12 p-5">
                <div class="w-full flex justify-between ">
                    <div class="flex items-center gap-3">
                        <el-avatar :src="user?.avatar ? toPortalImage(user?.avatar) : '/images/icons/logo-piggi.png'" class="bg-[#FFFFFF]" />
                        <span v-if="user" class="text-base font-semibold text-[#007E06] ">
                            {{ user?.name?.slice(0, user?.name?.length - 3) + '***' }}
                        </span>
                        <img v-else src="/images/icons/logo-text-piggi.png" class="h-3">
                    </div>
                    <i class="el-icon-right text-2xl" />
                </div>
                <div class="flex flex-col text-base mt-3" style="border-top: 2px solid #EDF5E6;">
                    <div class="mt-6">
                        <nuxt-link to="/" @click="close">
                            Trang chủ
                        </nuxt-link>
                    </div>
                    <!-- <div class="mt-6" @click="close">
                        <nuxt-link to="/ma-giam-gia">
                            Mã giảm giá
                        </nuxt-link>
                    </div> -->
                    <div class="mt-6">
                        <nuxt-link to="/ma-giam-gia" @click="close">
                            Mã giảm giá
                        </nuxt-link>
                    </div>
                    <!-- <div class="mt-6" @click="close">
                        <nuxt-link to="/ma-da-luu">
                            Mã đã lưu
                        </nuxt-link>
                    </div>
                    <div class="mt-6" @click="close">
                        <nuxt-link to="/thong-bao">
                            Thông báo
                        </nuxt-link>
                    </div> -->
                </div>
                <div class="flex flex-col text-base mt-5" style="border-top: 2px solid #EDF5E6;">
                    <div class="mt-6">
                        <nuxt-link to="/tin-khuyen-mai" @click="close">
                            Tin khuyến mãi
                        </nuxt-link>
                    </div>
                    <div class="mt-6">
                        <nuxt-link to="/faq" @click="close">
                            Trung tâm hỗ trợ
                        </nuxt-link>
                    </div>
                </div>
                <!-- <div class="flex flex-col text-base mt-5" style="border-top: 2px solid #EDF5E6;">
                    <div v-if="!user" class="mt-6" @click="handleLoginLoyalty(true)">
                        Tích điểm đổi quà
                    </div>
                    <nuxt-link
                        v-else
                        class="mt-6"
                        to="/tich-diem-doi-qua"
                        @click="close"
                    >
                        Tích điểm đổi quà
                    </nuxt-link>
                    <div class="mt-6" @click="signOut">
                        {{ user ? 'Đăng xuất' : 'Đăng nhập' }}
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';
    import { image as toImage, toPortalImage } from '@/utils/url';

    const routerAuth = ['lich-su-tich-diem-doi-qua', 'tich-diem-doi-qua', 'tich-diem-doi-qua-pageSlug'];
    export default {
        data() {
            return {
                showNav: false,
            };
        },
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('mobileDrawer', ['isShowNav']),
        },
        watch: {
            isShowNav() {
                this.showNav = false;
            },
            showNav() {
                this.setIsOpeningPopup(!!this.showNav);
            },
        },

        methods: {
            toImage,
            toPortalImage,
            ...mapMutations('popup', ['setIsOpeningPopup']),
            close() {
                this.showNav = false;
            },
            handleFormHiddenNav(item) {
                this.showNav = item;
                this.$store.dispatch('mobileDrawer/setShowNav', false);
            },
            async signOut() {
                if (this.user) {
                    await this.$auth.logout();
                    this.$message({
                        message: 'Đăng xuất thành công',
                        type: 'success',
                        offset: 60,
                    });
                    if (routerAuth.includes(this.$router.currentRoute.name)) {
                        this.$router.push('/');
                    }
                    return;
                }
                this.showNav = false;
                this.$emit('loginPopup', true);
            },
            handleLoginLoyalty(value) {
                if (this.user) {
                    return;
                }
                this.$store.dispatch('auth/setRedirect', value);
                this.$emit('loginPopup', true);
                this.showNav = false;
            },
        },
    };
</script>

<style lang="sass">
.mobileNavWrapper
    visibility: hidden
    opacity: 0
    transition: opacity 0.1s linear
    .register-btn
        .el-button
            @apply rounded-xl
.openNav
    visibility: visible
    opacity: 1

.mobileNav
    position: absolute
    transition: all 0.4s ease-out
    top: 0px
    right: -100%
    max-width: 300px
.slideNav
    right: 0
</style>
