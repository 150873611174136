<template>
    <div class="px-5 max-w-1200 mx-auto lg:mt-12 lg:py-0 lg:px-5">
        <div class="flex justify-between pt-2 bg-contain bg-no-repeat">
            <div class="flex mb-4 h-8 items-end">
                <span class="text-base font-semibold lg:text-2xl">Câu hỏi thường gặp</span>
                <!-- <nuxt-img
                    src="/images/peach-blossom.svg"
                    alt="Hoa đào"
                    height="44"
                    width="auto"
                    class="h-6 md:h-11"
                /> -->
            </div>
            <span>
                <i class="el-icon-arrow-right font-semibold text-lg" />
            </span>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-4">
            <!-- Tối đa 8 item -->
            <div v-for="(faq, index) in faqs.slice(0, 8)" :key="index">
                <nuxt-link :to="`/${faq.slug}`" class="w-full shadow-md relative bg-white py-4 px-4 mb-3 rounded-md lg:w-full truncate">
                    {{ faq.title }}
                </nuxt-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            faqs: {
                type: Array,
                required: true,
            },
        },
    };
</script>
