<template>
    <div>
        <el-dialog
            :visible="showDialog"
            :before-close="close"
            :close-on-click-modal="false"
            title="Đăng nhập"
            custom-class="popup-login"
            top="20vh"
            destroy-on-close
        >
            <LoadingForm :loading="loading" />
            <template v-if="isOpenLogin" #title>
                <div>
                    <p class="text-[#F23C37] mb-4">
                        *Bạn cần đăng nhập để tích điểm đổi quà
                    </p>
                    <p class="text-xl leading-6 font-medium text-[#003A03]">
                        Đăng nhập
                    </p>
                </div>
            </template>
            <div class="bg-[#FFFFFF] rounded-[15px] p-3">
                <el-form
                    ref="form"
                    :model="form"
                    :rules="rules"
                >
                    <div class=" mb-6 ">
                        <el-form-item class="mb-3 label-input-login" label="Số điện thoại" prop="phoneNumber">
                            <el-input v-model="form.phoneNumber" class="input-login" placeholder="Số điện thoại" />
                        </el-form-item>
                        <el-form-item class="mb-3 label-input-login" label="Mật khẩu" prop="password">
                            <el-input
                                v-model="form.password"
                                class="input-login"
                                placeholder="Mật khẩu"
                                prefix-icon="el-icon-lock"
                                :type="showPassword ? 'text' : 'password'"
                            >
                                <template #suffix>
                                    <i class="el-input__icon el-icon-view font-bold" :class="{ 'text-[#C0C4CC]': !showPassword, 'text-[#292929]': showPassword }" @click="toggleShowPassword" />
                                </template>
                            </el-input>
                        </el-form-item>
                        <div class="mb-3 text-xs font-medium text-[#292929] text-end">
                            <span @click="confirmPhone">Quên mật khẩu?</span>
                        </div>
                        <el-button class="w-full bg-[#008A07] text-[#FFFFFF] text-sm rounded-lg" @click="onsubmit">
                            Đăng nhập
                        </el-button>
                    </div>
                    <div class="flex justify-center p-2 gap-2">
                        <span>Bạn chưa có tài khoản?</span>
                        <span class="text-[#008A07] font-medium text-sm" @click="handleRegister">Đăng Ký</span>
                    </div>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    import { validPhone } from '../../utils/form';
    import LoadingForm from './LoadingForm.vue';

    export default {
        components: {
            LoadingForm,
        },
        data() {
            return {
                dialogVisible: false,
                showPassword: false,
                loading: false,
                form: {
                    phone: null,
                    password: '',
                },
                rules: {
                    phoneNumber: [
                        { required: true, message: 'Vui lòng nhập số điện thoại', trigger: 'change' },
                        { validator: validPhone, trigger: 'change' },
                    ],
                    password: [
                        { required: true, message: 'Vui lòng nhập mật khẩu', trigger: 'change' },
                    ],
                },
            };
        },
        computed: {
            ...mapState('auth', ['isRedirect', 'isOpenLogin']),
            showDialog() {
                return this.dialogVisible || this.isOpenLogin;
            },
        },
        methods: {
            ...mapActions('auth', ['setIsOpenLogin', 'setRedirect']),
            open() {
                this.dialogVisible = true;
            },
            close() {
                this.dialogVisible = false;
                if (this.isOpenLogin) {
                    this.$router.push('/');
                    this.setIsOpenLogin(false);
                }
            },
            toggleShowPassword() {
                this.showPassword = !this.showPassword;
            },
            handleRegister() {
                this.$emit('registerPopup', true);
                this.dialogVisible = false;
            },
            confirmPhone() {
                this.$emit('phonePopup', true);
                this.dialogVisible = false;
            },
            async onsubmit() {
                this.$refs.form.validate(async (valid) => {
                    if (valid) {
                        try {
                            this.loading = true;
                            this.$auth.options.redirect = false;
                            await this.$auth.loginWith('cookie', {
                                data: {
                                    phoneNumber: this.form.phoneNumber,
                                    password: this.form.password,
                                },
                            });
                            if (this.isRedirect) {
                                this.loading = false;
                                this.$message({
                                    message: 'Đăng nhập thành công',
                                    type: 'success',
                                    offset: 60,
                                    duration: 1500,
                                });
                                this.dialogVisible = false;
                                this.$emit('loginPopup', false);
                                this.$store.dispatch('mobileDrawer/setShowNav', true);
                                this.setRedirect(false);
                                this.$router.push('/tich-diem-doi-qua');

                                return;
                            }
                            this.$router.app.refresh();
                            this.loading = false;
                            this.$message({
                                message: 'Đăng nhập thành công',
                                type: 'success',
                                offset: 60,
                                duration: 1500,
                            });
                            this.$store.dispatch('mobileDrawer/setShowNav', true);
                            this.setIsOpenLogin(false);
                            this.dialogVisible = false;
                            this.$emit('loginPopup', false);
                        } catch (e) {
                            if (e.response) {
                                this.loading = false;
                                return this.$message({
                                    message: e.response.data.message,
                                    type: 'error',
                                    duration: 1500,
                                });
                            }
                        }
                    }
                });
            },
        },
    };
</script>
<style lang="scss">
 .el-dialog {
    max-width: 1200px;
    border-radius: 15px;
    width: 95vw !important;
}
.input-login {
        .el-input__inner {
            border: 1px solid #292929 !important;
        }
    }
.label-input-login {
.el-form-item__label{
    color: #292929;
}
    }
    .popup-login{
    position: relative;
        .el-dialog__header{
            padding: 20px 20px 0px;
        }
        .el-dialog__title{
            color: #003A03;
            font-size: 20px;
            font-weight: 500;
        }
        .el-dialog__headerbtn .el-dialog__close {
            color: #292929;
            font-size: 18px;

        }
        .el-dialog__body {
            padding: 0;
        }
    }
</style>
