const state = () => ({
    productSelected: [],
    openModal: false,
    productDetail: {},
    productModel: {},
});

const actions = {
    setProductSelected({ commit }, id) {
        commit('setProductSelected', id);
    },
    clearSelected({ commit }) {
        commit('clearSelected');
    },
    seeDetails({ commit }, product) {
        commit('seeDetails', product);
    },
    setProductModel({ commit }, product) {
        commit('setProductModel', product);
    },
};
const mutations = {
    setProductSelected(state, id) {
        if (!state.productSelected.some((item) => item === id)) {
            state.productSelected.push(id.toString());
        } else {
            state.productSelected = state.productSelected.filter((e) => e !== id);
        }
    },
    clearSelected(state) {
        state.productSelected = [];
    },
    seeDetails(state, product) {
        state.openModal = !state.openModal;
        state.productDetail = product;
    },
    setProductModel(state, product) {
        state.productModel = product;
    },
};

export {
    state,
    actions,
    mutations,
};
