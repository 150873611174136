export default (axios) => ({
    getProductRecommended: (itemid, shopid, catid, keyword, limit) => axios.get(`/amusement/get-items-recommended?itemid=${itemid}&shopid=${shopid}&catid=${catid}&keyword=${keyword}&upstream=search&limit=${limit || 6}`).then((_) => _.data),

    getHistoryPrice: (itemid, shopid, price) => axios.post('/amusement/belief-breath', { itemid, shopid, price }).then((_) => _.data),

    getVoucherByCate: (product) => axios.post('/amusement/attraction-arch', {
        HOSTTIME: product.mainCateId, EPR: product.EPR, OFFP: product.OFFP, ALGRO: product.ALGRO, LLWW: product.LLWW, ...product,
    }).then((_) => _.data).catch(() => null),

    getEventElementShopee: () => axios.get('/amusement/afterthought').then((_) => _.data),

    getShopInfo: (params) => axios.get('/amusement/shop-info', { params }).then((_) => _.data),

});
