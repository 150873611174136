export const NEW = 'new';
export const NORMAL = 'normal';

export const OPTIONS = [{
    label: 'Tất cả khách hàng',
    value: NORMAL,
}, {
    label: 'Khách hàng mới',
    value: NEW,
}];

export const VOUCHER_TYPE = {
    wallet: 'Có sẵn trong TK',
    manual_entry: 'Mã nhập tay',
    banner: 'Lưu trên banner',
};
