<template>
    <div v-show="loading" class="loading-spinner" body>
        <i class="el-icon-loading text-[#FFFFFF] text-[32px]" />
    </div>
</template>
<script>
    export default {
        props: {
            loading: {
                type: Boolean,
                default: true,
            },
        },
    };
</script>
<style lang="scss">
    .loading-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 15px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
}
</style>
