import Vue from 'vue';
import formatDate from '~/filters/formatDate';
import formatDuration from '~/filters/formatDuration';
import formatMoney from '~/filters/formatMoney';
import formatTitle from '~/filters/formatTitle';
import formatNumber from '~/filters/formatNumber';
import formatPhone from '~/filters/formatPhone';
import formatThousand from '~/filters/formatThousand';

export default () => {
    Vue.filter('formatDate', formatDate);
    Vue.filter('formatOnlyDate', (date) => formatDate(date, 'DD-MM-YYYY'));
    Vue.filter('formatDuration', formatDuration);
    Vue.filter('formatMoney', formatMoney);
    Vue.filter('formatTitle', formatTitle);
    Vue.filter('formatNumber', formatNumber);
    Vue.filter('formatThousand', formatThousand);
    Vue.filter('formatPhone', formatPhone);
};
