export const baseUrl = process.browser ? `${window.location.protocol}//${window.location.host}` : process.env.APP_URL;

export function image(imageItem, size = 'full') {
    if (imageItem) {
        if (!imageItem.includes('http')) {
            return size
                ? `${process.env.IMAGE_BASE_URL}/${size}/${imageItem}`
                : `${process.env.IMAGE_BASE_URL}/${imageItem}`;
        }
        imageItem = imageItem.includes('https://v-images.bloggiamgia.vn') ? imageItem.replace('v-images.bloggiamgia', 'images.bloggiamgia') : imageItem;
    }
    return imageItem || '';
}
export function toPortalImage(imageItem) {
    if (imageItem?.includes('http' || '')) {
        return imageItem;
    }
    return `${process.env.IMAGE_BASE_PORTAL_URL}/${imageItem}`;
}
