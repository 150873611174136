const state = () => ({
    isOpeningPopup: false,
    closePopupSearch: false,
});

const actions = {
    setClosePopupSearch({ commit }, status) {
        commit('setClosePopupSearch', status);
    },
};

const mutations = {
    setIsOpeningPopup(state, isOpeningPopup) {
        state.isOpeningPopup = isOpeningPopup;
    },
    setClosePopupSearch(state, closePopupSearch) {
        state.closePopupSearch = closePopupSearch;
    },
};

export {
    state,
    actions,
    mutations,
};
