const state = () => ({
    hotDealProducts: [],
    hotDealVouchers: [],
    pagination: {},
    paginationVoucher: {},
});

const mutations = {
    setHotDealProduct(state, payload) {
        state.hotDealProducts = payload.data;
        state.pagination = {
            page: payload.page,
            pagesize: payload.pagesize,
            total: payload.total,
        };
    },
    setHotDealVoucher(state, payload) {
        state.hotDealVouchers = payload.data.data;
        state.paginationVoucher = {
            page: payload.page,
            pagesize: payload.pagesize,
            total: payload.total,
        };
    },
};
const actions = {
    async getHotDealProducts({ commit }) {
        try {
            const hotDealProducts = await this.$api.hotDeal.getHotDealProducts({ pageSize: 9999 });
            commit('setHotDealProduct', hotDealProducts.data);
        } catch (error) {
            console.error(error);
        }
    },
    async getHotDealVouchers({ commit }) {
        try {
            const vouchers = await this.$api.hotDeal.getHotDealVouchers({ pageSize: 9999 });
            commit('setHotDealVoucher', vouchers.data);
        } catch (error) {
            console.error(error);
        }
    },

};

export {
    state,
    actions,
    mutations,
};
