const state = () => ({
    data: null,
    user: null,
    isRedirect: false,
    tokenForgotPass: '',
    isOpenLogin: false,
});

const actions = {
    setFormRegister({ commit }, item) {
        commit('setFormRegister', item);
    },
    setUser({ commit }, item) {
        commit('setUser', item);
    },
    setRedirect({ commit }, item) {
        commit('setRedirect', item);
    },
    setToken({ commit }, item) {
        commit('setToken', item);
    },
    setIsOpenLogin({ commit }, item) {
        commit('setIsOpenLogin', item);
    },
};
const mutations = {
    setFormRegister(state, item) {
        state.data = item;
    },
    setUser(state, item) {
        state.user = item;
    },
    setRedirect(state, item) {
        state.isRedirect = item;
    },
    setToken(state, item) {
        state.tokenForgotPass = item;
    },
    setIsOpenLogin(state, item) {
        state.isOpenLogin = item;
    },
};

export {
    state,
    actions,
    mutations,
};
