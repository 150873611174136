import dayjs from 'dayjs';

/**
 * @param {Date|number|string} date
 * @param {string} format
 * @param {string|null} fromFormat
 */
export default (date, toFormat = 'HH:mm DD-MM-YYYY', fromFormat) => {
    // eslint-disable-next-line eqeqeq
    const then = parseFloat(date) == date ? dayjs(date) : dayjs(date, fromFormat);

    return then.format(toFormat);
};
