import { findIndex } from 'lodash';

export const state = () => ({
    discounts: [],
    discount: null,
    pagination: null,
});

export const getters = {
    getDiscounts(state) {
        return state.discounts;
    },
};

export const mutations = {
    setDiscountsData(state, payload) {
        state.discounts = payload.data;
        state.pagination = {
            page: payload.page,
            pageSize: payload.pageSize,
            total: payload.total,
            lastPage: Math.ceil(payload.total / payload.pageSize),
        };
    },
    setDiscount(state, payload) {
        state.discount = payload.data;
    },
    setUpdateDiscount(state, payload) {
        const newItem = payload.data;
        const index = findIndex(state.discounts, { id: payload.data.id });
        state.discounts.splice(index, 1, { ...index, ...newItem });
    },
};

export const actions = {
    async fetch({ commit }, params) {
        const res = await this.$axios.get('/admin/voucher', { params });
        commit('setDiscountsData', res.data);
    },

    async show({ commit }, id) {
        const res = await this.$axios.get(`/admin/voucher/show/${id}`);
        commit('setDiscount', res.data);
    },
};
