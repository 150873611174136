const state = () => ({
    isShowNav: false,
});

const actions = {
    setShowNav({ commit }, item) {
        commit('setShowNav', item);
    },

};
const mutations = {
    setShowNav(state, item) {
        state.isShowNav = item;
    },
};

export {
    state,
    actions,
    mutations,
};
