import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6bb181e3 = () => interopDefault(import('../src/pages/error.vue' /* webpackChunkName: "pages/error" */))
const _0585f674 = () => interopDefault(import('../src/pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _2531be0e = () => interopDefault(import('../src/pages/lazada.vue' /* webpackChunkName: "pages/lazada" */))
const _746b2bc2 = () => interopDefault(import('../src/pages/lich-su-tich-diem-doi-qua/index.vue' /* webpackChunkName: "pages/lich-su-tich-diem-doi-qua/index" */))
const _3bf37a92 = () => interopDefault(import('../src/pages/ma-giam-gia/index.vue' /* webpackChunkName: "pages/ma-giam-gia/index" */))
const _5593b9e4 = () => interopDefault(import('../src/pages/shopee/index.vue' /* webpackChunkName: "pages/shopee/index" */))
const _976b10ea = () => interopDefault(import('../src/pages/tai-app.vue' /* webpackChunkName: "pages/tai-app" */))
const _207d997f = () => interopDefault(import('../src/pages/tich-diem-doi-qua/index.vue' /* webpackChunkName: "pages/tich-diem-doi-qua/index" */))
const _1a31d99b = () => interopDefault(import('../src/pages/tin-khuyen-mai.vue' /* webpackChunkName: "pages/tin-khuyen-mai" */))
const _34dd7fde = () => interopDefault(import('../src/pages/tin-khuyen-mai/index.vue' /* webpackChunkName: "pages/tin-khuyen-mai/index" */))
const _3326de96 = () => interopDefault(import('../src/pages/tin-khuyen-mai/_slug.vue' /* webpackChunkName: "pages/tin-khuyen-mai/_slug" */))
const _27eb63ad = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _03cf552c = () => interopDefault(import('../src/pages/faq/_slug.vue' /* webpackChunkName: "pages/faq/_slug" */))
const _160499c4 = () => interopDefault(import('../src/pages/ma-giam-gia/_pageSlug.vue' /* webpackChunkName: "pages/ma-giam-gia/_pageSlug" */))
const _32473434 = () => interopDefault(import('../src/pages/tich-diem-doi-qua/_pageSlug.vue' /* webpackChunkName: "pages/tich-diem-doi-qua/_pageSlug" */))
const _2634c265 = () => interopDefault(import('../src/pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/error",
    component: _6bb181e3,
    name: "error"
  }, {
    path: "/faq",
    component: _0585f674,
    name: "faq"
  }, {
    path: "/lazada",
    component: _2531be0e,
    name: "lazada"
  }, {
    path: "/lich-su-tich-diem-doi-qua",
    component: _746b2bc2,
    name: "lich-su-tich-diem-doi-qua"
  }, {
    path: "/ma-giam-gia",
    component: _3bf37a92,
    name: "ma-giam-gia"
  }, {
    path: "/shopee",
    component: _5593b9e4,
    name: "shopee"
  }, {
    path: "/tai-app",
    component: _976b10ea,
    name: "tai-app"
  }, {
    path: "/tich-diem-doi-qua",
    component: _207d997f,
    name: "tich-diem-doi-qua"
  }, {
    path: "/tin-khuyen-mai",
    component: _1a31d99b,
    children: [{
      path: "",
      component: _34dd7fde,
      name: "tin-khuyen-mai"
    }, {
      path: ":slug",
      component: _3326de96,
      name: "tin-khuyen-mai-slug"
    }]
  }, {
    path: "/",
    component: _27eb63ad,
    name: "index"
  }, {
    path: "/faq/:slug",
    component: _03cf552c,
    name: "faq-slug"
  }, {
    path: "/ma-giam-gia/:pageSlug?",
    component: _160499c4,
    name: "ma-giam-gia-pageSlug"
  }, {
    path: "/tich-diem-doi-qua/:pageSlug?",
    component: _32473434,
    name: "tich-diem-doi-qua-pageSlug"
  }, {
    path: "/:slug",
    component: _2634c265,
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
