import { render, staticRenderFns } from "./OTP.vue?vue&type=template&id=4756ed66"
import script from "./OTP.vue?vue&type=script&lang=js"
export * from "./OTP.vue?vue&type=script&lang=js"
import style0 from "./OTP.vue?vue&type=style&index=0&id=4756ed66&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports