const state = () => ({
    voucherCategories: null,
});

const actions = {
    async getVoucherCategories({ commit }, params) {
        const { data: voucherCategories } = await this.$api.voucherCategory.getVoucherCategory(params);
        commit('setVoucherCategories', voucherCategories?.data);
    },
    async getVoucherVoucherCategories({ commit }, params) {
        commit('setVoucherVoucherCategories', params);
    },
};

const mutations = {
    setVoucherCategories(state, voucherCategories) {
        state.voucherCategories = voucherCategories;
    },
};

export {
    state,
    actions,
    mutations,
};
