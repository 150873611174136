<template>
    <div>
        <el-dialog
            :visible.sync="showPopup"
            :before-close="close"
            custom-class="preview-banner"
            top="12vh"
            append-to-body
        >
            <div ref="container" class="relative container-img" @click="activeLink = null">
                <nuxt-img width="100%" :src="toImage(banner.image)" />
                <a
                    v-for="item in banner.IntentPopupLink"
                    :key="item.id"
                    :href="item.link"
                    class="absolute"
                    target="_blank"
                    :style="{top: item.yAxis, left: item.xAxis, width: item.widthBox, height: item.heightBox}"
                />
                <i class="el-icon-close absolute text-white font-black text-3xl cursor-pointer rounded-md" style="top: -25px; right: -15px; font-weight: 900; background-color: #3e3e3e;" @click="close" />
                <div class="absolute -bottom-10 text-white flex items-center font-semibold text-sm" cursor-pointer>
                    <el-checkbox v-model="checked" class="close-popup mr-2" />
                    Không hiển thị lại trong hôm nay
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import dayjs from 'dayjs';
    import { image as toImage } from '~/utils/url';
    import { mapState } from 'vuex';

    export default {
        props: {
            linkImg: String,
        },
        data() {
            return {
                dialogVisible: false,
                banner: '',
                closedBanner: {},
                checked: false,
                isPreview: false,
            };
        },
        computed: {
            ...mapState('popup', ['isOpeningPopup']),
            showPopup() {
                return !this.isOpeningPopup && this.dialogVisible;
            },
        },
        methods: {
            toImage,
            open(bannerPopup, isPreview) {
                if (this.closedBanner[bannerPopup.id] || this.dialogVisible) {
                    return;
                }
                if (isPreview) {
                    this.dialogVisible = true;
                    this.banner = bannerPopup;
                    this.isPreview = true;
                    return;
                }
                let banner = localStorage.getItem('banner-popup');
                banner = banner ? JSON.parse(banner) : {};
                this.isPreview = false;
                if (banner[bannerPopup.id] > dayjs().unix()) {
                    return;
                }
                this.dialogVisible = true;
                this.banner = bannerPopup;
            },
            close() {
                if (!this.checked || this.isPreview) {
                    this.dialogVisible = false;
                    this.closedBanner = { ...this.closedBanner, [this.banner?.id]: true };
                    return;
                }

                let banner = localStorage.getItem('banner-popup');
                banner = banner ? JSON.parse(banner) : {};
                this.dialogVisible = false;
                const exp = dayjs.min(dayjs().add(this.banner.limitLifeTime, 'hour'), dayjs().endOf('date')).unix();
                const value = { ...banner, [this.banner.id]: exp };
                localStorage.setItem('banner-popup', JSON.stringify(value));
            },

        },
    };
</script>
<style lang="scss">
    .el-dialog.preview-banner {
        background: none;
        box-shadow:unset;
        width: calc(100vw - 40px) !important;
        max-width: 500px !important;
        .el-dialog__header {
            padding: 0;
            display: none;
        }
        .el-dialog__body {
            padding: 0;

        }
    }
    .close-popup {
        .el-checkbox__inner {
            width: 26px;
            height: 26px;
            background-color: transparent;
            border-width: 2px;
            border-radius: 6px;
            &::after {
                width: 8px;
                height: 16px;
                border-width: 3px;
                top: 0;
                left: 6px;
            }
        }
    }
</style>
