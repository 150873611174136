<template>
    <div class="expried-date flex justify-center min-w-[96px] sm:min-w-[106px] pl-2 mr-2">
        <div v-if="isStarted" class="flex justify-center items-center" :class="!voucher.expiredAt && 'flex-col' ">
            <span class="text-right text-[12px] mr-1">
                <i class="el-icon-time" /> HSD:
            </span>
            <span class=" text-left italic text-[12px]">
                {{ voucher.expiredAt ? dayjs(voucher.expiredAt).format('DD/MM') : 'Đang cập nhật' }}
            </span>
        </div>
        <div v-else class="flex flex-wrap md:justify-center items-center text_custom md:max-w-[95px] w-full">
            <span class="text-center text-[#017407] mr-1">
                <i class="el-icon-time" /> Hiệu lực:
            </span>
            <span class="italic text-xs text-center5 ml-2 text-[12px]">
                {{ (voucher.startAt) ? dayjs(voucher.startAt).format('HH:mm DD/MM') : 'Đang cập nhật' }}
            </span>
        </div>
    </div>
</template>
<script>
    import dayjs from 'dayjs';

    export default {
        props: {
            voucher: {
                type: Object,
                required: true,
            },
        },
        computed: {
            isStarted() {
                return !this.voucher.startAt || dayjs(this.voucher.startAt).isBefore(dayjs());
            },
        },
        methods: {
            dayjs,
        },
    };
</script>
<style lang="scss">

</style>
