export default function(to, from) {
      if (to.hash) {
        window.scrollTo({
          top: document.querySelector(to.hash).offsetTop + window.innerHeight,
          behavior: 'smooth'
        });
      }

      if (to.path !== from.path && process.client) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
