const state = () => ({
    url: '',
});

const actions = {
    setUrlHistoryVoucherLazada({ commit }, item) {
        commit('setUrlHistoryVoucherLazada', item);
    },
};
const mutations = {
    setUrlHistoryVoucherLazada(state, item) {
        state.url = item;
    },
};

export {
    state,
    actions,
    mutations,
};
