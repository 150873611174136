<template>
    <div class="px-3 max-w-1200 mx-auto lg:py-0 lg:px-5">
        <div class="flex justify-between">
            <span class="text-base font-semibold mb-4 lg:text-2xl">{{ title }}</span>
        </div>
        <div v-if="vouchers && vouchers.length > 0">
            <div class="mt-2 grid mb-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 lg:gap-4">
                <ClientOnly>
                    <template v-for="(voucher) in showedVoucher.slice(0, 12)">
                        <HotDealProduct
                            v-if="voucher?.offer === 'HotDealProduct'"
                            :key="voucher._id"
                            :hot-deal-product="voucher"
                        />
                        <HotDealVoucher
                            v-else-if="voucher?.offer === 'HotDealVoucher'"
                            :key="'HotDealVoucher' + voucher._id"
                            :voucher="voucher"
                            :suppliers="suppliers"
                        />
                        <Voucher
                            v-else
                            :key="'voucher' + voucher.id"
                            :suppliers="suppliers"
                            :voucher="voucher"
                            :category="categories.filter((e) => e.id === voucher.couponCategoryId)[0]"
                        />
                    </template>
                </ClientOnly>
            </div>
        </div>
        <el-empty
            v-else
            class="flex-grow"
            description="Trống"
        />
    </div>
</template>

<script>
    import Voucher from '~/components/shared/Voucher.vue';
    import { image as toImage } from '~/utils/url';
    import HotDealVoucher from '@/components/shared/HotDealVoucher.vue';
    import HotDealProduct from '@/components/shared/HotDealProduct.vue';
    import { cloneDeep } from 'lodash';
    import _sample from 'lodash/_arraySample';
    import { mapState } from 'vuex';

    export default {
        components: {
            Voucher,
            HotDealProduct,
            HotDealVoucher,
        },
        props: {
            title: {
                type: String,
            },

            vouchers: {
                type: Array,
                required: true,
            },

            suppliers: {
                type: Array,
                required: true,
            },
            categories: {
                type: Array,
                required: true,
            },
            itemPingo: {
                type: Array,
            },
        },
        async fetch() {
            const { data: banners } = await this.$api.banners.getBanners({ type: 'middle-home' });
            const { data: couponCategoriesWithChild } = await this.$axios.get('/nb/VoucherCategory/all').then((_) => _.data);
            this.categoriesOther = couponCategoriesWithChild;
            if (banners.length > 0) {
                this.banners = banners;
            } else {
                this.banners = [
                    {
                        image: 'https://thuvienmuasam.com/uploads/default/original/2X/9/909ffea787ef5eade561a31d2d4204db59228205.jpeg',
                    },
                ];
            }
        },

        data() {
            return {
                banners: [],
                categoriesOther: [],
                swiperOption: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    loop: true,
                },
            };
        },
        computed: {
            ...mapState('hotDeal', ['hotDealProducts', 'hotDealVouchers']),
            showedVoucher() {
                const vouchersTemp = cloneDeep(this.vouchers);
                const hotDealProducts = cloneDeep(this.hotDealProducts);
                const hotDealVouchers = cloneDeep(this.hotDealVouchers);
                const deals = [];
                let newArr = [];
                for (let i = 0; i < hotDealProducts.length + hotDealVouchers.length; i += 1) {
                    if (i < hotDealProducts.length) {
                        deals.push({ ...hotDealProducts[i], offer: 'HotDealProduct' });
                    }
                    if (i < hotDealVouchers.length) {
                        deals.push({ ...hotDealVouchers[i], offer: 'HotDealVoucher' });
                    }
                    const spliceNum = _sample([2, 3]);
                    if (spliceNum > vouchersTemp.length) break;
                    newArr = [...newArr, ...vouchersTemp.splice(0, spliceNum), deals[i]];
                }
                newArr = [...newArr, ...vouchersTemp];
                return newArr;
            },
        },
        methods: {
            toImage,
        },
    };
</script>
<style>
   .el-empty__description p {
        color:#6a6e74;
    }
    .poster-list-voucher .swiper-container {
        height:100%;
    }
</style>
