import axiosPortal from '@/libs/axios-portal';
import auth from '@/api-portal/auth';

export default (context, inject) => {
    // Initialize API factories
    const factories = {
        auth: auth(axiosPortal),
    };

    // Inject $api
    inject('apiPortal', factories);
};
