const state = () => ({
    active: '',
    voucherLoyalty: {},
    history: [],
    wallet: [],
    pagination: {},
    paginationWallet: {},
    loading: false,
});

const actions = {
    setActive({ commit }, item) {
        commit('setActive', item);
    },
    setVoucherLoyalty({ commit }, item) {
        commit('setVoucherLoyalty', item);
    },
    async getHistory({ commit }, params) {
        try {
            commit('setLoading', true);
            const { data: history } = await this.$api.loyalty.getHistory(params);
            commit('setHistory', history.data);
            commit('setPagination', {
                pageSize: history.pageSize,
                total: history.total,
                page: history.page,
            });
            commit('setLoading', false);
        } catch (error) {
            console.error(error);
            commit('setLoading', false);
        }
    },
    async getWallet({ commit }, params) {
        try {
            commit('setLoading', true);

            const { data: wallet } = await this.$api.loyalty.getWallet(params);
            commit('setWallet', wallet.data);
            commit('setWalletPagination', {
                pageSize: wallet.pageSize,
                total: wallet.total,
                page: wallet.page,
            });
            commit('setLoading', false);
        } catch (error) {
            console.error(error);
            commit('setLoading', false);
        }
    },
};
const mutations = {
    setActive(state, item) {
        state.active = item;
    },
    setVoucherLoyalty(state, item) {
        state.voucherLoyalty = item;
    },
    setLoading(state, item) {
        state.loading = item;
    },
    setHistory(state, item) {
        state.history = [...state.history, ...item];
    },
    setPagination(state, item) {
        state.pagination = item;
    },
    setWallet(state, item) {
        state.wallet = [...state.wallet, ...item];
    },
    setWalletPagination(state, item) {
        state.paginationWallet = item;
    },
};

export {
    state,
    actions,
    mutations,
};
