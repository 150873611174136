<template>
    <el-dialog
        :visible.sync="visible"
        :before-close="close"
        class="popup-notification-mobile"
    >
        <div class="text-[14px] text-[#F23C37] font-semibold text-center">
            Tính năng này chỉ hỗ trợ trên thiết bị di động Sếp nhé!
        </div>
        <img
            src="/images/loyalty/noti-gift.webp"
            fit="cover"
            alt="image_noti"
            loading="lazy"
            width="200"
        >
    </el-dialog>
</template>
<script>
    export default {
        data() {
            return {
                visible: true,
            };
        },
        methods: {
            close() {
                this.visible = false;
                this.$emit('isPopupNotificationMobile', false);
            },
        },
    };
</script>
<style lang="scss">
.popup-notification-mobile .el-dialog{
    width: 370px !important;
    border-radius: 16px !important;
    width: 370px !important;
    .el-dialog__body{
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
    }
}

</style>
