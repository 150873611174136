const state = () => ({
    status: false,
    voucherCode: null,
    copyStatus: false,
    forceRecheckStatus: false,
    totalRecheckStatus: 0,
    totalRecheckStatusFalse: 0,
    countFindVoucher: 0,
    totalCheckedStatus: 0,
});

const actions = {
    setStatus({ commit }, status) {
        commit('setStatus', status);
    },
    setVoucherCode({ commit }, voucherCode) {
        commit('setVoucherCode', voucherCode);
    },
    setCopyStatus({ commit }, copyStatus) {
        commit('setCopyStatus', copyStatus);
    },
    setForceRecheckStatus({ commit }, forceRecheckStatus) {
        commit('setForceRecheckStatus', forceRecheckStatus);
    },
    setTotalRecheckStatus({ commit }, totalRecheckStatus) {
        commit('setTotalRecheckStatus', totalRecheckStatus);
    },
    setTotalRecheckStatusFalse({ commit }, totalRecheckStatusFalse) {
        commit('setTotalRecheckStatusFalse', totalRecheckStatusFalse);
    },
    setCountFindVoucher({ commit }, CountFindVoucher) {
        commit('setCountFindVoucher', CountFindVoucher);
    },
    setTotalCheckedStatus({ commit }, totalCheckedStatus) {
        commit('setTotalCheckedStatus', totalCheckedStatus);
    },
};

const mutations = {
    setStatus(state, status) {
        state.status = status;
    },
    setVoucherCode(state, voucherCode) {
        state.voucherCode = voucherCode;
    },
    setCopyStatus(state, copyStatus) {
        state.copyStatus = copyStatus;
    },
    setForceRecheckStatus(state, forceRecheckStatus) {
        state.forceRecheckStatus = forceRecheckStatus;
    },
    setTotalRecheckStatus(state, totalRecheckStatus) {
        state.totalRecheckStatus = totalRecheckStatus;
    },
    setTotalCheckedStatus(state, totalCheckedStatus) {
        state.totalCheckedStatus = totalCheckedStatus;
    },
    setTotalRecheckStatusFalse(state, totalRecheckStatusFalse) {
        state.totalRecheckStatusFalse = totalRecheckStatusFalse;
        if (state.totalRecheckStatus <= totalRecheckStatusFalse && totalRecheckStatusFalse > 0) {
            state.forceRecheckStatus = true;
            state.totalRecheckStatus = 0;
            state.TotalRecheckStatusFalse = 0;
        }
    },
    setCountFindVoucher(state, CountFindVoucher) {
        state.CountFindVoucher = CountFindVoucher;
    },
};

export {
    state,
    actions,
    mutations,
};
