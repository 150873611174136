export default (axios) => ({
    getVouchers: (params) => axios.get('/voucher', { params: { sort: 'totalClick,DESC', ...params } }).then((_) => _.data),
    getAllVouchers: (params) => axios.get('/b/Voucher', { params }).then((_) => _.data),
    getVouchersBySlug: (voucherSlug, params) => axios.get(`/b/Voucher/${voucherSlug}`, { params }).then((_) => _.data),
    getProductByLink: (data) => axios.post('/amusement/brother-battle', data).then((_) => _.data),
    getHotVouchers: (params) => axios.get('/b/Voucher/home-hot', { params }).then((_) => _.data),
    getShopeeVouchers: (params) => axios.get('/b/Voucher/home-shopee', { params }).then((_) => _.data),
    bookmarkVoucher: (id) => axios.put(`/b/Voucher/bookmark/${id}`).then((_) => _.data),
    getBookmarkVouchers: (params) => axios.get('/b/Voucher/BookmarkVouchers', { params }).then((_) => _.data),
    responseVoucher: (data) => axios.post('/responseVoucher', data).then((_) => _.data),
    affLinksOnetime: (data) => axios.post('/shopee/handle_link_one_time', data).then((_) => _.data),

    getProductLazada: (data) => axios.post('/kango/mount-gone', data).then((_) => _.data),
});
